module.exports = {
  // General
  'About {appName}': 'Um {appName}',
  'About Enable Banking API': 'Um Enable Banking API',
  'Application description is not provided.': 'Umsóknarlýsing er ekki gefin upp.',
  'Authenticate': 'Staðfesta',
  'Authentication method': 'Auðkenningaraðferð',
  'Authentication session has expired': 'Auðkenningarfundur er útrunninn',
  'Cancel': 'Hætta við',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Smelltu á hnappinn {0} ef þú hefur staðfest heimild í BankID appinu.',
  'Continue authentication': 'Halda áfram auðkenningu',
  'Enter your OTP code with index {0}': 'Sláðu inn OTP kóðann þinn með vísitölunni {0}',
  'Error during authentication:': 'Villa við auðkenningu:',
  'Error while updating authentication status': 'Villa við að uppfæra auðkenningarstöðu',
  'Open the BankID app on this device': 'Opnaðu BankID appið á þessu tæki',
  'OTP code': 'OTP kóða',
  'Redirecting to your account servicing provider': 'Beinir til þjónustuveitunnar reikningsins þíns',
  'Returning to the authentication initiator': 'Farið aftur í upphafsmann auðkenningar',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Ræstu BankID appið á farsímanum þínum og pikkaðu á QR kóða hnappinn í appinu til að skanna QR kóðann á þessari síðu',
  'or': 'eða',
  'Starting authorization': 'Byrjunarheimild',
  'Start typing for filtering the list': 'Byrjaðu að slá inn til að sía listann',
  'To continue please use your authentication app': 'Til að halda áfram skaltu nota auðkenningarforritið þitt',
  'Waiting for completion of authentication': 'Beðið eftir að auðkenningu sé lokið',
  'Try again': 'Reyndu aftur',
  'Submit': 'Sendu inn',
  'Unable to access server': 'Get ekki fengið aðgang að þjóninum',
  'Unable to authenticate': 'Ekki hægt að auðkenna',
  'Unable to continue authorization': 'Ekki er hægt að halda áfram heimild',
  'Unable to continue decoupled authorization': 'Ekki er hægt að halda áfram ótengdri heimild',
  'Unable to handle authentication status "{status}"': 'Ekki hægt að höndla auðkenningarstöðu „{status}“',
  'Unknown error occurred': 'Óþekkt villa kom upp',
  'You need to close this window': 'Þú þarft að loka þessum glugga',
  // Consent
  'Authentication is initiated by {0}.': '{0} hefur virkjað auðkenningu.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Eftir að þú hefur lokið auðkenningu verður birtur listi yfir greiðslureikninga þína, innstæður reikninga og færslur sem verður svo deilt með þeim aðila sem virkjaði auðkenningu.',
  'your payment accounts data': 'gögn um greiðslureikninga þína',
  'the list of your payment accounts': 'lista yfir greiðslureikninga þína',
  'the list of your payment accounts and accounts balances': 'lista yfir greiðslureikninga þína og reikningastöðu',
  'the list of your payment accounts and accounts transactions': 'lista yfir greiðslureikninga þína og reikningafærslur',
  'the list of your payment accounts, accounts balances and transactions': 'listann yfir greiðslureikninga þína, innstæður reikninga og færslur',
  'By pressing {0} button you agree with the {1}.': 'Með því að ýta á hnappinn {0} samþykkir þú {1}.',
  'terms of Enable Banking API service': 'skilmála Enable Banking API þjónustu',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Ýttu á hnappinn {0} ef þú ert ekki tilbúinn til að deila reikningsupplýsingunum þínum.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API er vefþjónusta sem gerir þér kleift að nota reikningsupplýsingar þínar frá völdum banka eða sambærilegri innlánsstofnun (greiðsluþjónustuveitendur sem veita reikningsþjónustu (e. Account Servicing Payment Service Provider, ASPSP) hjá öðrum stafrænum þjónustum.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Til þess að gera það verður þú að auðkenna þig í ASPSP þjónustunni.',
  'The service was formerly known as Tilisy business API.': 'Þjónustan var áður þekkt sem Tilisy Business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Samþykki þitt fyrir notkun á reikningsupplýsingunum er vistað hjá Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Sem notandi þjónustunnar er auðkenningartákn þitt og sérstakt kennimerki samþykkis þíns vistað þar.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Þegar þú hefur gefið samþykki þitt í ASPSP þjónustunni opnar Enable Banking API fyrir aðgang að reikningsupplýsingunum þínum svo að stafræna þjónustan sem þú ert að nota (þ.e. sá sem virkjar auðkenningu þína) geti tekið á móti gögnunum.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Reikningsupplýsingarnar þínar streyma þá í gegnum Enable Banking API en eru ekki vistaðar þar.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API er í eigu og starfrækt af Enable Banking Oy, staðsett í Otakaari 5, 02150 Espoo, Finnlandi, fyrirtækjaauðkenni 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Fyrirtækið er skráður reikningsupplýsingaþjónustuveitandi (e. Account Information Service Provider, AISP) og er undir eftirliti finnska fjármálaeftirlitsins (FIN-FSA).',
  'The registration details can be found from {0}.': 'Nánari upplýsingar má finna á {0}.',
  'the FIN-FSA register for payment services providers': 'skrá Fjármálaeftirlitsins fyrir greiðsluþjónustuaðila',
  'Please read the {0} carefully before continuing to authentication.': 'Vinsamlegast lestu {0} vandlega yfir áður en þú heldur áfram í auðkenningu.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Þú getur skoðað skilmálana og viðbótarupplýsingar um Enable Banking API þjónustuna hvenær sem er hér: {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Aðrar upplýsingar og uppfærslur um þjónustuna, eða sviksamlega eða samsvarandi starfsemi sem gæti ógnað öruggri notkun þjónustunnar, verða sendar notendum sérstaklega frá stafrænu þjónustunni og/eða Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Þjónustuskilmálar',
  'Last updated:': 'Síðast uppfærðir:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Vinsamlegast lestu þjónustuskilmála þessa vandlega yfir áður en þú notar þjónustu Enable Banking API.',
  'Description of the service': 'Lýsing á þjónustunni',
  'Processing of personal data': 'Vinnsla persónuupplýsinga',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking vinnur eingöngu með persónuupplýsingar í þeim tilgangi og að því marki sem Enable Banking API þjónustan getur virkað fyrir þig.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Við vinnslu persónuupplýsinga skuldbindur Enable Banking API sig til að fara að lögum um persónuvernd og vinnslu persónuupplýsinga sem og annarri löggjöf sem við á, sér í lagi hina almennu persónuverndarreglugerð ESB (ESB 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Nánara innihald vinnslu persónuupplýsinga, þar á meðal:',
  '(a) the nature and purpose of the processing;': '(a) eðli og tilgangur vinnslunnar;',
  '(b) the type of personal data and categories of data subjects;': '(b) tegund persónuupplýsinga og flokkar skráðra einstaklinga;',
  '(c) the applicable data protection measures;': '(c) viðeigandi öryggisráðstafanir;',
  '(d) the customer’s rights and responsibilities;': '(d) réttindi og skyldur viðskiptavinarins;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': '(e) markmið og tímalengd vinnslu, skal lýst nánar í Persónuverndarstefnu.',
  'Links to other websites': 'Tenglar á aðrar vefsíður',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Þjónusta Enable Banking gæti innihaldið tengla eða vísað þér á vefsíður eða þjónustu þriðja aðila sem eru ekki í eigu eða undir stjórn fyrirtækisins, þar með talið, en ekki takmarkað við, vefsíður eða aðra greiðsluþjónustuveitendur sem veita reikningsþjónustu.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Fyrirtækið hefur enga stjórn á, og tekur enga ábyrgð á, innihaldi, persónuverndarstefnu eða starfsháttum slíkra vefsíðna eða þjónustu þriðja aðila.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Þú samþykkir ennfremur að fyrirtækið beri ekki ábyrgð á hvers kyns tjóni eða tapi, beinu eða óbeinu, sem talið er vera eða geta verið af völdum þjónustunnar, í tengslum við notkun á efni þriðja aðila eða vöru eða þjónustu sem er aðgengileg í gegnum slíkar vefsíður eða þjónustur.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Við ráðleggjum þér eindregið að lesa vel skilmála og persónuverndarstefnu allra vefsíðna eða þjónustu þriðja aðila sem þú heimsækir.',
  'No warranty': 'Engin ábyrgð',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Án takmörkunar á því sem kemur fram hér að ofan þá getur hvorki fyrirtækið né þjónustuaðilar félagsins ábyrgst á nokkurn hátt:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) rekstur eða aðgengi þjónustunnar, upplýsingarnar, innihaldið og efnið eða vörurnar sem fylgja henni;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) að þjónustan verði án truflana eða villulaus;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) nákvæmni, áreiðanleika eða samræmi við nýjustu útgáfu og uppfærslur upplýsinga eða efnis sem veitt er í gegnum þjónustuna.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Sum lögsagnarumdæmi leyfa ekki útilokun á ákveðnum tegundum ábyrgða eða takmarkanir á viðeigandi lögbundnum réttindum neytenda, þannig að sumar eða allar ofangreindar útilokanir og takmarkanir eiga ekki við um þig.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Í slíku tilviki skal þó beita útilokunum og takmörkunum sem settar eru fram í þessum kafla að því marki sem hægt er að framfylgja samkvæmt gildandi lögum.',
  'Changes': 'Breytingar',
  'The Company reserves the right to modify or replace the Service at any time.': 'Fyrirtækið áskilur sér rétt til að gera breytingar á eða skipta út þjónustunni hvenær sem er.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Ef endurskoðun á skilmálum þessum er nauðsynleg mun fyrirtækið gera sitt besta til að tilkynna þér áður en nýir skilmálar taka gildi ásamt öðrum stafrænum þjónustuaðilum.',
  'You can also check any updates from this page.': 'Þú getur líka séð allar uppfærslur á þessari síðu.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Með því að halda aðgangi opnum að reikningsupplýsingum þínum eða nota þjónustuna eftir að breytingar taka gildi, samþykkir þú endurskoðun eða uppfærslu á skilmálunum.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Ef þú samþykkir ekki nýju skilmálana, í heild eða að hluta, vinsamlegast hættu að nota þjónustuna.',
  'Termination': 'Uppsögn',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Ef vegna einhverra ástæðna viltu ekki lengur nota þjónustuna til að deila greiðslutökuupplýsingum þínum með öðrum stafrænum þjónustum, þá vinsamlegast {0}.',
  'visit the Data Sharing Consents page': 'heimsækja síðuna um samþykki um gögnadeilingu',
  'Upon termination, your right to use the Service will cease immediately.': 'Við uppsögn lokast aðgangur þinn að þjónustunni þegar í stað.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Við getum einnig, hvenær sem er og án fyrirvara, lokað fyrir aðganginn þinn, einhliða og án ábyrgðar, af hvaða ástæðu sem er.',
  'Governing law': 'Gildandi lög',
  'The laws of Finland shall govern your use of the Service.': 'Um skilmála þessa og þjónustu gilda finnsk lög.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Ef þú ert neytandi í Evrópusambandinu munt þú þó njóta góðs af öllum lögboðnum ákvæðum laga þess lands sem þú ert búsettur í.',
  'Disputes resolution': 'Úrlausn ágreiningsmála',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Ef þú hefur einhverjar áhyggjur eða telur brotið á rétti þínum þá ertu beðin/n vinsamlegast að hafa samband svo við getum leyst úr erindi þínu áður en til formlegs ágreinings kemur.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Neytendur í Finnlandi eiga einnig rétt á því að vera í sambandi við neytendamálanefnd, Hämeentie 3, P.O. Box 306, 00531 Helsinki, s. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Öðrum málefnum fyrirtækisins skal beint til Fjármálaeftirlitsins.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Samskiptaupplýsingar FIN-FSA eru: Snellmaninkatu 6, 00100 Helsinki, Finnland, s. +358 9 183 51 (skiptiborð); www.finanssivalvonta.fi.',
  'Contact us': 'Hafðu samband við okkur',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Ef þú hefur einhverjar spurningar um þjónustuskilmála þessa getur þú haft samband við okkur með tölvupósti á {0}.',
  // Backend messages
  '10 digit client number': '10 stafa viðskiptavinanúmer',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10 stafa fyrirtækisnúmer (eða 12 stafa persónunúmer ef fyrirtækið er einstaklingsfyrirtæki)',
  '10 digit organization number.': '10 stafa fyrirtækisnúmer.',
  '17 digit card number (starting with 6703)': '17 stafa kortanúmer (byrjar á 6703)',
  '6 digit Handelsbanken customer number': '6 stafa viðskiptamannsnúmer Handelsbanken',
  '8 digits CVR number': '8 stafa CVR númer',
  '9 digits Organisasjonsnummer': '9 tölustafir Organisasjonsnummer',
  'Agreement number': 'Samningsnúmer',
  'Agreement number for Nordea Corporate Netbank': 'Samningsnúmer fyrir Nordea Corporate Netbank',
  'Bank user ID': 'Notandaauðkenni banka',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Kennitala fyrirtækja (Y-tunnus) á sniði 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Auðkenniskóði fyrirtækis sem birtist í stillingum fyrirtækjanetbanka',
  'Client ID': 'Auðkenni viðskiptavinar',
  'Client number': 'Númer viðskiptavinar',
  'Company ID': 'Auðkenni fyrirtækis',
  'Company number': 'Fyrirtækjanúmer',
  'Corporate ID': 'Auðkenni fyrirtækja',
  'Corporate registration ID': 'Skráningarauðkenni fyrirtækja',
  'Currency code': 'Gjaldmiðilskóði',
  'Digit card number': 'Tölustafa kortanúmer',
  'Disposer': 'Forráðamaður',
  'E-mail': 'Tölvupóstur',
  'E-mail used for authenticating to Holvi': 'Tölvupóstur notaður til að auðkenna Holva',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Annað hvort 14 stafa SEB auðkenni fyrirtækisins eða 10 stafa fyrirtækisnúmerið',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (án undirreikningsnúmers): 3 stafa útibúsnúmer + 7 stafa viðskiptanúmer',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Fyrir sænsk fyrirtæki er þetta stofnunarnúmerið eða SHB-númerið og fyrir einkaaðila (Enskild firma) er það persónulegt númer þeirra, sem öll eru 10 tölustafir.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, vegabréf',
  'Login ID used in MyBRD aplications': 'Innskráningarauðkenni notað í MyBRD forritum',
  'Login Name': 'Aðgangsnafn',
  'Mandate ID': 'Auðkenni umboðs',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norskt kennitala á sniðinu DDMMYYXXXXXX (11 tölustafir)',
  'Online banking PIN': 'PIN fyrir netbanka',
  'Online banking Password': 'Lykilorð fyrir netbanka',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Auðkenni fyrirtækis á sniðinu TBxxxxx eða TXxxxxx',
  'Organisation number': 'Stofnunarnúmer',
  'PIN': 'PIN',
  'Password': 'Lykilorð',
  'Password for Aktia Internet bank': 'Lykilorð fyrir Aktia netbanka',
  'Password used for authenticating to Holvi': 'Lykilorð notað til að auðkenna Holvi',
  'Personal ID': 'Persónuskilríki',
  'Personal ID number': 'Persónunúmer',
  'Personal code': 'Persónulegur kóða',
  'Personal code of the user': 'Persónulegur kóða notandans',
  'Personal signatory number': 'Persónulegt undirritaranúmer',
  'Personal username for online banking': 'Persónulegt notendanafn fyrir netbanka',
  'Postbank ID': 'Póstbankakenni',
  'Social security number (Personnummer)': 'Kennitala (Persónunúmer)',
  'Social security number in the format YYYYMMDDXXXX': 'Kennitala á sniðinu ÁÁÁÁMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Sænskt kennitala á sniðinu YYYYMMDDXXXX',
  'The IBAN of the user authenticating': 'IBAN notandans sem auðkennir',
  'The currency code': 'Gjaldmiðilskóðinn',
  'The user ID of the user authenticating': 'Notandaauðkenni notandans sem auðkennir',
  'Unique personal ID number': 'Einstök kennitala',
  'User ID': 'notandanafn',
  'User ID for Aktia Internet bank': 'Notandakenni fyrir Aktia netbanka',
  'User ID for Nordea Corporate Netbank': 'Notandaauðkenni fyrir Nordea Corporate Netbank',
  'User ID for online banking': 'Notandanafn fyrir netbanka',
  'User ID in the format XX000000': 'Notandaauðkenni á sniðinu XX000000',
  'User ID used to log in to the bank': 'Notandanúmer notað til að skrá sig inn í bankann',
  'Username': 'Notendanafn',
  'Username used to log in to the bank': 'Notendanafn notað til að skrá sig inn í bankann',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO notendanafn'
}
