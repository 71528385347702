<template>
  <div class="loading-content">
    <div class="text-center">
      <Spinner v-if="loading" class="mx-auto" />
      <div class="text-dark">{{ message }}</div>
      <div class="text-danger">{{ error }}</div>
    </div>
    <auth-form :authOptions="authOptions" :error="error"
      v-if="authInputRequired && !loading && !authDataRequired && !bankIdAppUri" v-on:submit="submitCredentials" />
    <div v-if="!loading && authDataRequired && !bankIdAppUri">
      <div v-if="authData.type == 'otpIndex'">
        <i18n tag="p" path="Enter your OTP code with index {0}" class="text-center"><strong>{{ authData.value
            }}</strong></i18n>
      </div>
      <div v-else-if="authData.type == 'message'">
        <p class="text-center"><strong>{{ authData.value }}</strong></p>
      </div>
      <div class="qrcode-container mb-4">
        <img id="qrCodeImage" class="qrcode-image" :src="qrCodeUri">
      </div>
      <div class="form-inline justify-content-center">
        <input type="text" name="otpCode" class="form-control" required :placeholder="$t('OTP code')" v-model="otpCode">
        <button class="btn btn-theme" v-on:click="submitOtpCode">{{ $t('Submit') }}</button>
      </div>
    </div>
    <div v-if="!loading && dataSharingConsentRequired">
      <div class="row justify-content-center mb-5">
        <div class="col-12 col-md-8 col-lg-6">
          <p class="text-justify">
            <i18n tag="span" path="Authentication is initiated by {0}." class="mr-1"><strong>{{ appName }}</strong>
            </i18n>
            <i18n tag="span"
              path="After you complete authentication, {0} will be shared with the authentication initiator."
              class="mr-1"><em>{{ $t(accessScope) }}</em></i18n>
            <i18n tag="span" path="By pressing {0} button you agree with the {1}." class="mr-1"><span>"{{ $t('Continue authentication') }}"</span><a href="https://tilisy.enablebanking.com/terms" target="_blank">{{ $t('terms of Enable Banking API service') }}</a></i18n>
            <i18n tag="span" path="Press {0} button if you are not willing to share your payment accounts data.">
              <span>"{{ $t('Cancel') }}"</span>
            </i18n>
          </p>
          <div class="form-inline justify-content-center">
            <button class="btn btn-theme" v-on:click="submitDataSharingConsent">{{ $t('Continue authentication')
              }}</button>
          </div>
          <div class="text-center">
            <button class="btn btn-link" :disabled="cancelDisabled" v-on:click="cancelAuth">
              {{ $t("Cancel") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 small text-muted">
          <p><strong>{{ $t('About {appName}', { appName: appName }) }}</strong></p>
          <p class="text-justify">{{ appDescriptionLocal }}</p>
          <about-service />
        </div>
      </div>
    </div>
    <bank-id v-if="!loading && bankIdAppUri" :bankIdAppUri="bankIdAppUri" :displayOnly="displayOnly"
      v-on:BankIdAppRedirect="openBankIdApp" v-on:BankIdResume="onBankIdResume" v-on:PageHidden="stopUpdate" />
    <div class="text-center" v-if="!loading && !dataSharingConsentRequired">
      <button class="btn btn-link" :disabled="cancelDisabled" v-on:click="cancelAuth">
        {{ $t("Cancel") }}
      </button>
    </div>
  </div>
</template>
<script>
import { i18n } from '../i18n.js'
import config from '../config.js'
import AboutService from '../components/AboutService.vue'
import AuthForm from '../components/AuthForm.vue'
import BankId from '../components/BankId.vue'
import Spinner from '../components/elements/Spinner.vue'
import BaseService from '../services/BaseService.js'

const REQUEST_LIMIT = 300

export default {
  name: 'AIS',
  components: {
    AboutService,
    AuthForm,
    BankId,
    Spinner
  },
  props: {
    widgetEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: '',
      loading: true,
      cancelDisabled: false,
      message: '',
      prevStatus: null,
      status: null,
      country: null,
      bankName: '',
      business: false,
      authOptions: [],
      authData: '',
      authDataRequired: false,
      otpCode: '',
      appName: '',
      appDescription: '',
      accessScope: 'your payment accounts data',
      dataSharingConsentRequired: false,
      bankIdAppUri: '',
      displayOnly: null,
      qrCodeUri: '',
      timeoutId: 0,
      redirectTimeoutId: 0,
      requestsCount: 0,
      lastVisibilitychange: new Date().getTime()
    }
  },
  computed: {
    authInputRequired: function () {
      return (this.authOptions.length > 1 || (
        this.authOptions.length === 1 &&
        this.authOptions[0].info &&
        this.authOptions[0].info.credentials &&
        this.authOptions[0].info.credentials.length
      )) || !!this.error
    },
    appDescriptionLocal: function () {
      if (typeof this.appDescription === 'string' && this.appDescription.length > 0) {
        return this.appDescription
      } else if (
        this.appDescription &&
        typeof this.appDescription[this.$i18n.locale] === 'string' &&
        this.appDescription[this.$i18n.locale].length > 0
      ) {
        return this.appDescription[this.$i18n.locale]
      } else {
        return this.$t('Application description is not provided.')
      }
    }
  },
  methods: {
    async handleWaitingUserDataSharingConsent (data) {
      this.appName = data.response.app_name
      this.appDescription = data.response.app_description
      this.dataSharingConsentRequired = true
      if (data.response.access) {
        if (data.response.access.balances && data.response.access.transactions) {
          this.accessScope = 'the list of your payment accounts, accounts balances and transactions'
        } else if (data.response.access.balances) {
          this.accessScope = 'the list of your payment accounts and accounts balances'
        } else if (data.response.access.transactions) {
          this.accessScope = 'the list of your payment accounts and accounts transactions'
        } else {
          this.accessScope = 'the list of your payment accounts'
        }
      }
      this.loading = false
    },
    async handleNotReady (data) {
      this.bankName = data.response.bank_name
      this.business = data.response.business
      this.authOptions = data.response.auth_info
      this.bankIdAppUri = ''
      this.qrCodeUri = ''
      if (data.error && data.error.message) {
        this.error = i18n.t('Error during authentication:') + ' ' + i18n.t(data.error.message)
      }
      if (this.authInputRequired) {
        this.loading = false
      } else {
        this.message = i18n.t('Starting authorization')
        const r = await BaseService.startAuthorization(undefined, undefined, this.$i18n.locale)
        console.log(r)
        this.scheduleUpdate(1000)
      }
    },
    async handleInvalid (data) {
      this.error = i18n.t('Unable to authenticate')
      if (data.response.redirect_url) {
        if (!data.response.is_pis) {
          this.message = i18n.t('Returning to the authentication initiator')
        }
        this.redirect(data.response.redirect_url)
      } else {
        this.message = i18n.t('You need to close this window')
      }
    },
    async handleCancelled (data) {
      this.error = 'Authentication cancelled'
      if (data.response.redirect_url) {
        if (!data.response.is_pis) {
          this.message = i18n.t('Returning to the authentication initiator')
        }
        this.redirect(data.response.redirect_url)
      } else {
        this.message = i18n.t('You need to close this window')
      }
    },
    async handleBankUI (data) {
      this.message = ''
      try {
        await BaseService.continueAuthorizationWithRedirectUrl(window.location.href, this.$i18n.locale)
        // Get rid of query and fragment
        window.history.replaceState({}, '', '/ais/')
      } catch (e) {
        throw new Error(i18n.t('Unable to continue authorization'))
      } finally {
        this.scheduleUpdate(500)
      }
    },
    async handleReturnedFromBank (data) {
      // continue authorization is already started. Just wait
      this.scheduleUpdate(500)
    },
    async handleWaitingUserUIAuth (data) {
      this.message = i18n.t('Redirecting to your account servicing provider')
      const r = await BaseService.notifyLeaving()
      if (r.data.response.message !== 'OK') {
        throw new Error(r.data.response.message)
      }
      window.localStorage.setItem('last-locale', this.$i18n.locale)
      this.redirect(data.response.redirect_url)
    },
    async handleWaitingUserDecoupledAuth (data) {
      this.message = ''
      if (data.response.auth_data) {
        this.authData = data.response.auth_data
        if (['otpIndex', 'message'].includes(this.authData.type)) {
          return this.handleBlockingAuthData(data)
        }
        return this.handleAuthData(data)
      } else {
        if (data.response.redirect_url) { // QR code generation flow
          this.loading = false
          this.bankIdAppUri = data.response.redirect_url
          const authHints = data.response.auth_url_hints
          if (authHints && authHints.length === 1) {
            this.displayOnly = authHints[0].auth_type
          }
          try {
            const r = await BaseService.continueAuthorization({ locale: this.$i18n.locale })
            console.log(r)
          } catch (e) {
            throw new Error(i18n.t('Unable to continue decoupled authorization'))
          }
          this.scheduleUpdate(0, this.loading)
        } else {
          this.message = i18n.t('Waiting for completion of authentication')
          try {
            const r = await BaseService.continueAuthorization({ locale: this.$i18n.locale })
            console.log(r)
          } catch (e) {
            throw new Error(i18n.t('Unable to continue decoupled authorization'))
          }
          this.scheduleUpdate(500)
        }
      }
    },
    handleBlockingAuthData (data) {
      this.authDataRequired = true
      this.otpCode = ''
      this.loading = false
      if (data.response.redirect_url) { // PUSH_OTP (see SDK issues #1277)
        const url = new URL(data.response.redirect_url)
        this.qrCodeUri = url.href
        this.$nextTick(() => {
          const qrCodeImage = document.getElementById('qrCodeImage')
          if (qrCodeImage === null) {
            // the element is not available
            return
          }
          qrCodeImage.style.display = 'block'
        })
      }
    },
    async handleAuthData (data) {
      this.loading = false
      this.bankIdAppUri = data.response.redirect_url
      this.displayOnly = this.authData.type
      try {
        const r = await BaseService.continueAuthorization({ locale: this.$i18n.locale })
        console.log(r)
      } catch (e) {
        throw new Error(i18n.t('Unable to continue decoupled authorization'))
      }
      this.scheduleUpdate(1000, this.loading)
    },
    async handleAuthDone (data) {
      this.bankIdAppUri = ''
      if (!data.response.is_pis) {
        this.message = i18n.t('Returning to the authentication initiator')
      } else {
        if (this.widgetEnabled) {
          this.$emit('redirect-pis', data)
          return
        }
      }
      this.redirect(data.response.redirect_url)
    },
    async handleAuthStarted (data) {
      // just wait
      this.scheduleUpdate(500)
    },
    redirect (redirectUrl) {
      this.loading = true
      this.redirectTimeoutId = setTimeout(() => {
        window.location.href = redirectUrl
      }, 500)
    },
    async updateStatus () {
      try {
        this.timeoutId = 0
        const r = await BaseService.getSessionStatus()
        this.error = ''
        this.prevStatus = this.status
        this.status = r.data.response.status
        // Checking country and updating locale if necessary
        if (r.data.response.country && r.data.response.country !== this.country) {
          this.country = r.data.response.country
          const urlLang = new URLSearchParams(window.location.search).get('locale')
          const selectedLang = window.localStorage.getItem('selected-language')
          if (config.countryLocales[this.country] &&
            config.countryLocales[this.country] !== this.$i18n.locale &&
            !(urlLang && this.$i18n.messages[urlLang]) &&
            !(selectedLang && this.$i18n.messages[selectedLang]) &&
            this.$i18n.messages[config.countryLocales[this.country]] &&
            (
              !r.data.response.language ||
              r.data.response.language.match(
                new RegExp(
                  // Checking if locale is in accepted languages (of it any are accepted)
                  `(^|[^\\w-])(${config.countryLocales[this.country]}|\\*)(\\W|$)`, 'i'
                )
              )
            )) {
            this.$root.$i18n.locale = config.countryLocales[this.country]
            window.document.documentElement.setAttribute(
              'lang',
              this.$root.$i18n.locale.toLocaleLowerCase()
            )
          }
        }
        const handler = this[`handle${this.status}`]
        if (!handler) {
          this.message = ''
          throw new Error(
            i18n.t(
              'Unable to handle authentication status "{status}"',
              { status: this.status }
            )
          )
        }
        handler(r.data)
      } catch (e) {
        let retry = true
        if (e.response) {
          if (e.response.status === 404) {
            this.error = i18n.t('Unable to access server')
            retry = false
          } else if (e.response.status === 422 &&
            e.response.data &&
            e.response.data.detail &&
            e.response.data.detail.find(d =>
              d.loc[0] === 'cookie' &&
              d.loc[1] === 'sessionid' &&
              d.type === 'value_error.missing')
          ) {
            try {
              await BaseService.restoreSession(window.location.href)
            } catch (e) {
              this.error = i18n.t('Authentication session has expired')
              retry = false
              try {
                const redirectKey = 'fallbackRedirectUri'
                const regex = new RegExp(`${redirectKey}="(?<${redirectKey}>.*)"`)
                const redirectUri = document.cookie.split(';').find(el => el.startsWith(redirectKey)).match(regex).groups[redirectKey]
                this.redirect(redirectUri)
              } catch (e) { }
            }
          } else {
            this.error = i18n.t('Error while updating authentication status')
          }
        } else if (e.message) {
          this.error = e.message
        } else {
          this.error = i18n.t('Unknown error occurred')
        }
        this.loading = retry
        if (retry && this.requestsCount < REQUEST_LIMIT) {
          this.scheduleUpdate(500)
        }
      } finally {
        this.requestsCount++
      }
    },
    scheduleUpdate (timeout, loading = true) {
      if (this.timeoutId !== 0) {
        // We don't want to schedule new update if there is already update scheduled
        return
      }
      this.loading = loading
      this.timeoutId = setTimeout(this.updateStatus, timeout)
    },
    async submitCredentials (data) {
      this.loading = true
      this.error = ''
      try {
        const r = await BaseService.startAuthorization(
          data.authMethod,
          Object.fromEntries(data.authCredentials.map(c => [c.name, c.value])),
          this.$i18n.locale
        )
        console.log(r)
      } catch (e) {
        console.error(e)
      }
      this.scheduleUpdate(500)
    },
    stopUpdate () {
      window.stop()
      clearTimeout(this.timeoutId)
      this.timeoutId = 0
    },
    openBankIdApp (data) {
      this.stopUpdate()
      if (data.blank) {
        window.open(data.redirectUri, '_blank')
      } else {
        window.location.href = data.redirectUri
      }
    },
    async onBankIdResume () {
      this.bankIdAppUri = ''
      this.loading = true
      await BaseService.continueAuthorization({ locale: this.$i18n.locale })
      this.scheduleUpdate(1000)
    },
    async submitOtpCode () {
      this.loading = true
      try {
        const r = await BaseService.continueAuthorizationWithOtpCode(this.otpCode, this.$i18n.locale)
        console.log(r)
      } catch (e) {
        console.error(e)
      }
      this.scheduleUpdate(500)
    },
    async submitDataSharingConsent () {
      this.loading = true
      try {
        const r = await BaseService.confirmDataSharingConsent()
        console.log(r)
      } catch (e) {
        console.error(e)
      }
      this.dataSharingConsentRequired = false
      this.scheduleUpdate(500)
    },
    isBackForwardNavigation () {
      const navigation = window.performance.getEntriesByType('navigation')
      if (navigation && navigation.length && navigation[0].type === 'back_forward') {
        return true
      }
      return false
    },
    async cancelAuth () {
      clearTimeout(this.timeoutId)
      this.timeoutId = 0
      if (this.redirectTimeoutId !== 0) {
        clearTimeout(this.redirectTimeoutId)
        this.redirectTimeoutId = 0
      }
      window.stop()
      this.loading = true
      this.cancelDisabled = true
      this.error = ''
      try {
        const r = await BaseService.cancelAuth()
        console.log(r)
      } catch (e) {
        console.error(e)
      }
      this.scheduleUpdate(500)
    }
  },
  async mounted () {
    if (this.isBackForwardNavigation() && !this.widgetEnabled) {
      await this.cancelAuth()
    } else {
      this.scheduleUpdate(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-content {
  padding-top: 20vh;
}

.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-image {
  border: 1px solid #ccc;
  display: none;
  width: 228px;
  height: 228px;
}
</style>
