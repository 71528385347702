module.exports = {
  // General
  'About {appName}': 'Om {appName}',
  'About Enable Banking API': 'Om Enable Banking API',
  'Application description is not provided.': 'Applikationsbeskrivning tillhandahålls inte.',
  'Authenticate': 'Autentisera',
  'Authentication method': 'Autentiseringsmetod',
  'Authentication session has expired': 'Autentiseringssessionen har löpt ut',
  'Cancel': 'Avbryt',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Klicka på knappen {0} om du har bekräftat auktorisationen i BankID-appen.',
  'Continue authentication': 'Fortsätt autentisering',
  'Enter your OTP code with index {0}': 'Ange din OTP-kod med index {0}',
  'Error during authentication:': 'Fel vid autentisering:',
  'Error while updating authentication status': 'Fel vid uppdatering av autentiseringsstatus',
  'Open the BankID app on this device': 'Öppna BankID-appen på den här enheten',
  'OTP code': 'OTP-kod',
  'Redirecting to your account servicing provider': 'Omdirigerar till din kontoserviceleverantör',
  'Returning to the authentication initiator': 'Återgår till autentiseringsinitiatören',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Starta BankID-appen på din mobila enhet och tryck på QR-kodknappen i appen för att skanna QR-koden på den här sidan',
  'or': 'eller',
  'Starting authorization': 'Startar auktorisering',
  'Start typing for filtering the list': 'Začnite tipkati za filtriranje seznama',
  'To continue please use your authentication app': 'Använd din autentiseringsapp för att fortsätta',
  'Waiting for completion of authentication': 'Väntar på slutförande av autentisering',
  'Try again': 'Försök igen',
  'Submit': 'Skicka',
  'Unable to access server': 'Det går inte att komma åt servern',
  'Unable to authenticate': 'Det gick inte att autentisera',
  'Unable to continue authorization': 'Det gick inte att fortsätta auktoriseringen',
  'Unable to continue decoupled authorization': 'Det gick inte att fortsätta frikopplad auktorisering',
  'Unable to handle authentication status "{status}"': 'Det går inte att hantera autentiseringsstatus "{status}"',
  'Unknown error occurred': 'Okänt fel har inträffat',
  'You need to close this window': 'Du måste stänga det här fönstret',
  // Consent
  'Authentication is initiated by {0}.': 'Autentisering initieras av {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Efter att du har slutfört identifieringsprocessen kommer {0} att vara tillgängliga för organisationen som påbörjat autentiseringsprocessen.',
  'your payment accounts data': 'dina betalningskontouppgifter',
  'the list of your payment accounts': 'listan över dina betalkonton',
  'the list of your payment accounts and accounts balances': 'listan över dina betalkonton och kontosaldon',
  'the list of your payment accounts and accounts transactions': 'listan över dina betalkonton och kontotransaktioner',
  'the list of your payment accounts, accounts balances and transactions': 'listan över dina betalkonton, kontosaldon och transaktioner',
  'By pressing {0} button you agree with the {1}.': 'Genom att trycka {0} godkänner du {1}.',
  'terms of Enable Banking API service': 'villkoren för Enable Banking API service',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Tryck {0} om du inte går med på att dela dina betalningsuppgifter.',
  // Payment consent
  'Payment details': 'Betalningsinformation',
  'Payment ID provided by the application: {0}': 'Betalnings-ID som tillhandahålls av applikationen: {0}',
  'Account number where to transfer funds: {0}': 'Kontonummer dit du kan överföra pengar: {0}',
  'Payee name in the payment: {0}': 'Betalningsmottagarens namn i betalningen: {0}',
  'Payee country: {0}': 'Betalningsmottagarens land: {0}',
  'Payment amount: {0}': 'Betalningsbelopp: {0}',
  'Message or reference number in the payment: {0}': 'Meddelande eller referensnummer i betalningen: {0}',
  'Payment is initiated by {0}.': 'Betalning initieras av {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'När du har slutfört autentiseringen kommer ditt betalningssamtycke att begäras av din kontoförmedlande betaltjänstleverantör.',
  'terms of service': 'användarvillkor',
  'Press {0} button if you are not willing to complete this payment.': 'Tryck på knappen {0} om du inte är villig att slutföra den här betalningen.',
  'Select Payment Account': 'Välj betalkonto',
  'Confirm': 'Bekräfta',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API gör det möjligt att använda uppgifterna för ditt betalningskonto hos en bank eller annan leverantör av betalningstjänster (Account Servicing Payment Service Provider, ASPSP) i andra digitala tjänster.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Användningen av dina betalningskontouppgifter förutsätter att du identifierar dig i den tjänsten i fråga.',
  'The service was formerly known as Tilisy business API.': 'Tjänsten var tidigare känd som Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Enable Banking API upprätthåller ditt explicita samtycke för användningen av dina betalningskontouppgifter.',
  'This service registers your PSU authentication tokens and consent ID.': 'Vi registrerar endast de uppgifter som behövs för identifiering.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Enable Banking API kan ge andra digitala tjänster tillgång till dina uppgifter efter att du har godkänt dem för detta i en tjänst som din bank eller annan betalningstjänstleverantör tillhandahåller.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Uppgifter om ditt betalningskonto överförs via Enable Banking API, men tjänsten varken sparar eller registrerar dessa uppgifter på något sätt.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API ägs och förvaltas av Enable Banking Ab, (Företaget,  Tjänsteleverantören), fo-nummer 2988499-7, Teknikvägen 2 F 309, 02150 Esbo, Finland.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Företaget har registrerats som en leverantör av kontoinformationstjänster (Account Information Service Provider, AISP) och övervakas av Finansinspektionen i Finland.',
  'The registration details can be found from {0}.': 'Uppgifterna om registreringen kan kontrolleras på {0}.',
  'the FIN-FSA register for payment services providers': 'Finansinspektionen webbplats',
  'Please read the {0} carefully before continuing to authentication.': 'Vi rekommenderar att du bekantar dig med våra {0} innan du identifierar dig i den tjänsten som din bank eller annan betalningstjänstleverantör tillhandahåller.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Användningsvillkoren och annan information om tjänsten finns alltid tillgänglig på {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Information om eventuella uppdateringar till tjänsten - samt om sådant missbruk eller annat otillbörligt bruk av den som kan äventyra dess datasäkerhet - ges via programvaran som levereras tillsammans med Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Användarvillkor',
  'Last updated:': 'Senast uppdaterad:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Läs dessa villkor för tjänsten noga innan du använder Enable Banking API.',
  'Description of the service': 'Beskrivning av tjänsten',
  'Processing of personal data': 'Behandling av personuppgifter',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking behandlar personuppgifter endast för ändamålet och i den utsträckning som Enable Banking API tjänst kan fungera för dig.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'När behandling av personuppgifter Enable Banking API åtar sig att följa den lagstiftning om behandling av personuppgifter och dataskydd samt annan lagstiftning tillämplig och särskilt dataskyddsförordning EU General (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'De mer detaljerade innehållet i personuppgifter, bland annat:',
  '(a) the nature and purpose of the processing;': '(a) naturen och ändamålet med behandlingen;',
  '(b) the type of personal data and categories of data subjects;': '(b) typ av personuppgifter och kategorier av registrerade;',
  '(c) the applicable data protection measures;': '(c) de tillämpliga dataskyddsåtgärder;',
  '(d) the customer’s rights and responsibilities;': '(d) kundens rättigheter och skyldigheter;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'och (e) föremålet och varaktigheten av den personliga databehandlingen skall beskrivas mer i detalj i integritetspolicyn.',
  'Links to other websites': 'Länkar till andra webbplatser',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking kan innehålla länkar eller omdirigera dig till tredje part webbplatser eller tjänster som inte ägs eller kontrolleras av bolaget, inklusive men ej begränsat till ASPSPs’ webbsidor eller tjänster.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Bolaget har ingen kontroll över, och tar inget ansvar för innehållet, sekretesspolicy eller praxis av någon tredje parts webbsidor eller tjänster.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Du bekräftar vidare och samtycker till att Bolaget inte skall vara ansvarig eller skyldig direkt eller indirekt, för någon skada eller förlust som orsakats eller påstås ha orsakats av eller i samband med användning av eller tillit till sådant innehåll, varor eller tjänster som är tillgängliga på eller via sådana webbplatser eller tjänster.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Vi råder dig att läsa villkoren och sekretesspolicy för någon tredje part webbsidor eller tjänster som du besöker.',
  'No warranty': 'Ingen garanti',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Utan att begränsa det föregående, varken bolaget eller något av bolagets leverantör gör någon utfästelse eller garanti av något slag, uttryckligen eller underförstått:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) såsom funktion eller tillgänglighet av tjänsten, eller den information, innehåll och material eller produkter som ingår därpå;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) att Tjänsten kommer att vara oavbruten eller felfri;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) att riktighet, tillförlitlighet eller valuta information eller innehåll som tillhandahålls via tjänsten.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Vissa jurisdiktioner tillåter inte att utesluta vissa typer av garantier eller begränsningar av tillämpliga lagstadgade rättigheter konsument, så några eller alla av de ovan nämnda undantag och begränsningar kanske inte gäller dig.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Men i ett sådant fall de undantag och begränsningar som anges i detta avsnitt skall tillämpas i störst utsträckning verkställbara enligt gällande lag.',
  'Changes': 'Ändringar',
  'The Company reserves the right to modify or replace the Service at any time.': 'Företaget förbehåller sig rätten att ändra eller byta ut tjänsten när som helst.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Om en revidering är väsentlig bolaget kommer att göra rimliga ansträngningar för att meddela dig innan några nya villkor, med effekt tillsammans med andra leverantörer digitala tjänster.',
  'You can also check any updates from this page.': 'Du kan också kontrollera eventuella uppdateringar från den här sidan.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Genom att fortsätta att komma åt eller använda Tjänsten efter dessa ändringar träder i kraft, godkänner du att vara bunden av de ändrade villkoren.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Om du inte accepterar de nya villkoren, helt eller delvis, sluta använda Tjänsten.',
  'Termination': 'Uppsägning',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Om du av någon anledning inte längre vill använda Tjänsten för att dela dina betalkontodetaljer med andra digitala tjänster, vänligen {0}.',
  'visit the Data Sharing Consents page': 'besök sidan för Datadelningssamtycken',
  'Upon termination, your right to use the Service will cease immediately.': 'Vid uppsägning, kommer din rätt att använda Tjänsten upphör omedelbart.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Vi kan säga upp eller avbryta din tillgång omedelbart, utan förvarning eller ansvar, av någon anledning.',
  'Governing law': 'Gällande lag',
  'The laws of Finland shall govern your use of the Service.': 'Lagarna i Finland ska styra din användning av tjänsten.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Om du är en EU-konsument kommer du dra nytta av några tvingande bestämmelser i lagen i det land där du är bosatt i.',
  'Disputes resolution': 'Tvistlösning',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Om du har någon oro eller tvist om tjänsten först ombeds du för att försöka lösa tvisten informellt genom att kontakta företaget.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Konsument användare i Finland har också rätt att vara i kontakt med Konsumenttvistenämnden, Tavastvägen 3, P.O. Box 306, 00531 Helsingfors, tel. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Företaget relaterade frågor ska riktas till tillsynsmyndigheten.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Finansinspektionen kontaktinformation: Snellmansgatan 6, 00100 Helsingfors, Finland, tel. 358 9 183 51 (växel); www.finanssivalvonta.fi.',
  'Contact us': 'Kontakta oss',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Om du har några frågor om tjänsten, kan du kontakta oss via e-post på {0}.',
  // Backend messages
  '10 digit client number': '10-siffrigt kundnummer',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-siffrigt organisationsnummer (eller 12-siffrigt personnummer om företaget är en enskild firma)',
  '10 digit organization number.': '10-siffrigt organisationsnummer.',
  '17 digit card number (starting with 6703)': '17-siffrigt kortnummer (börjar med 6703)',
  '6 digit Handelsbanken customer number': '6-siffrigt Handelsbankens kundnummer',
  '8 digits CVR number': '8-siffrigt CVR-nummer',
  '9 digits Organisasjonsnummer': '9 siffror Organisationsnummer',
  'Agreement number': 'Avtalsnummer',
  'Agreement number for Nordea Corporate Netbank': 'Nordea Corporate Netbank avtalsnummer',
  'Bank user ID': 'Bank användar-ID',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Företagsidentitetskod (Y-tunnus) i 1234567-8-format',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Affärsidentitetskod som visas i företagets internetbankinställningar',
  'Client ID': 'Klient ID',
  'Client number': 'Klient nummer',
  'Company ID': 'Företags ID',
  'Company number': 'Företagsnummer',
  'Corporate ID': 'Företags-ID',
  'Corporate registration ID': 'Företagsregistrerings-ID',
  'Currency code': 'Valutakod',
  'Digit card number': 'Sifferkortsnummer',
  'Disposer': 'Avfallshanterare',
  'E-mail': 'E-post',
  'E-mail used for authenticating to Holvi': 'E-post som används för autentisering till Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Antingen den 14-siffriga SEB-identifieraren för företaget eller det 10-siffriga organisationsnumret',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (utan underkontonummer): 3-siffrigt kontorsnummer + 7-siffrigt kundnummer',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'För Svenska företag är detta deras organisationsnummer eller SHB-nummer, och för Enskild firma är det deras personnummer, som alla är 10 siffror.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, pass',
  'Login ID used in MyBRD aplications': 'Inloggnings-ID som används i MyBRD-applikationer',
  'Login Name': 'Inloggningsnamn',
  'Mandate ID': 'Fullmakts-ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norskt personnummer i formatet DDMMYYXXXXXX (11 siffror)',
  'Online banking PIN': 'PIN-kod för internetbank',
  'Online banking Password': 'Lösenord för internetbank',
  'Organisation ID in the format TBxxxxx, TXxxxxx, TUxxxxx or NUxxxxx': 'Organisations-ID i formatet TBxxxxx eller TXxxxxx eller TUxxxxx eller NUxxxxx',
  'Organisation number': 'Organisationsnummer',
  'PIN': 'PIN',
  'Password': 'Lösenord',
  'Password for Aktia Internet bank': 'Lösenord för Aktia Internetbank',
  'Password used for authenticating to Holvi': 'Lösenord används för autentisering till Holvi',
  'Personal ID': 'Personligt ID',
  'Personal ID number': 'Personnummer',
  'Personal code': 'Personlig kod',
  'Personal code of the user': 'Användarens personliga kod',
  'Personal signatory number': 'Personligt firmatecknarnummer',
  'Personal username for online banking': 'Personligt användarnamn för internetbank',
  'Postbank ID': 'Postbank ID',
  'Social security number (Personnummer)': 'Personnummer (personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Personnummer i formatet ÅÅÅÅMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Svenskt personnummer i formatet ÅÅÅÅMMDDXXXX',
  'The IBAN of the user authenticating': 'IBAN för användaren som autentiserar',
  'The currency code': 'Valutakoden',
  'The user ID of the user authenticating': 'Användar-ID för användaren som autentiserar',
  'Unique personal ID number': 'Unikt personnummer',
  'User ID': 'Användar ID',
  'User ID for Aktia Internet bank': 'Användar-ID för Aktia Internetbank',
  'User ID for Nordea Corporate Netbank': 'Användar-ID för Nordea Corporate Netbank',
  'User ID for online banking': 'Användar-ID för internetbank',
  'User ID in the format XX000000': 'Användar-ID i formatet XX000000',
  'User ID used to log in to the bank': 'Användar-ID som används för att logga in på banken',
  'Username': 'Användarnamn',
  'Username used to log in to the bank': 'Användarnamn som används för att logga in på banken',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO användarnamn'
}
