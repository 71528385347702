module.exports = {
  // General
  'About {appName}': 'À propos de {appName}',
  'About Enable Banking API': 'A propos de Enable Banking API',
  'Application description is not provided.': 'La description de l\'application n\'est pas fournie.',
  'Authenticate': 'S\'authentifier',
  'Authentication method': 'Méthode d\'authentification',
  'Authentication session has expired': 'La session d\'authentification a expiré',
  'Cancel': 'Annuler',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Cliquez sur le bouton {0} si vous avez confirmé l\'autorisation dans l\'application BankID.',
  'Continue authentication': 'Continuer l\'authentification',
  'Enter your OTP code with index {0}': 'Entrez votre code OTP avec l\'index {0}',
  'Error during authentication:': 'Erreur lors de l\'authentification :',
  'Error while updating authentication status': 'Erreur lors de la mise à jour du statut d\'authentification',
  'Open the BankID app on this device': 'Ouvrez l\'application BankID sur cet appareil',
  'OTP code': 'Code OTP',
  'Redirecting to your account servicing provider': 'Redirection vers votre prestataire de services de compte',
  'Returning to the authentication initiator': 'Retour à l\'initiateur d\'authentification',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Démarrez l\'application BankID sur votre appareil mobile et appuyez sur le bouton du code QR dans l\'application pour scanner le code QR sur cette page',
  'or': 'ou',
  'Starting authorization': 'Démarrage de l\'authorization',
  'Start typing for filtering the list': 'Commencez à taper pour filtrer la liste',
  'To continue please use your authentication app': 'Pour continuer, veuillez utiliser votre application d\'authentification',
  'Waiting for completion of authentication': 'Attente de la fin de l\'authentification',
  'Try again': 'Réessayer',
  'Submit': 'Envoyer',
  'Unable to access server': 'Impossible d\'accéder au serveur',
  'Unable to authenticate': 'Impossible de s\'authentifier',
  'Unable to continue authorization': 'Impossible de continuer l\'autorisation',
  'Unable to continue decoupled authorization': 'Impossible de continuer l\'autorisation Decoupled',
  'Unable to handle authentication status "{status}"': 'Impossible de gérer l\'état d\'authentification "{status}"',
  'Unknown error occurred': 'Une erreur inconnue s\'est produite',
  'You need to close this window': 'Vous devez fermer cette fenêtre',
  // Consent
  'Authentication is initiated by {0}.': 'L\'authentification est initiée par {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Une fois l\'authentification terminée, {0} seront partagées avec l\'initiateur de l\'authentification.',
  'your payment accounts data': 'les données de vos comptes de paiement',
  'the list of your payment accounts': 'la liste de vos comptes de paiement',
  'the list of your payment accounts and accounts balances': 'la liste de vos comptes de paiement et soldes de comptes',
  'the list of your payment accounts and accounts transactions': 'la liste de vos comptes de paiement et opérations de comptes',
  'the list of your payment accounts, accounts balances and transactions': 'la liste de vos comptes de paiement, soldes de comptes et opérations',
  'By pressing {0} button you agree with the {1}.': 'En appuyant sur le bouton {0}, vous acceptez les {1}.',
  'terms of Enable Banking API service': 'conditions du service Enable Banking API',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Appuyez sur le bouton {0} si vous ne souhaitez pas partager les données de vos comptes de paiement.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API est un service en ligne qui vous permet d\'utiliser les données de votre compte de paiement d\'une banque choisie ou d\'un organisme similaire (Account Servicing Payment Service Provider, l\'ASPSP) dans d\'autres services numériques.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Pour utiliser les données de votre compte de paiement, vous devez vous authentifier dans le service ASPSP.',
  'The service was formerly known as Tilisy business API.': 'Le service était auparavant connu sous le nom Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Votre consentement explicite à l\'utilisation des données du compte de paiement sera conservé dans Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Ce service enregistre vos jetons d\'authentification PSU et votre identifiant de consentement.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Une fois que vous avez donné votre consentement dans le service ASPSP, Enable Banking API ouvre l\'accès aux données de votre compte de paiement afin que le service numérique que vous utilisez (i.e. l\'initiateur d\'authentification) puisse récupérer ces données.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Les données de votre compte de paiement transitent par Enable Banking API et n\'y seront pas enregistrées.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API est détenu et exploité par Enable Banking Oy, situé à Otakaari 5, 02150 Espoo, Finlande, ID de société 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'La société est un fournisseur de services enregistré (Fournisseur de services d\'informations sur les comptes, AISP) et supervisé par l\'Autorité de surveillance financière finlandaise (FIN-FSA).',
  'The registration details can be found from {0}.': 'Les détails de l\'enregistrement peuvent être trouvés à partir du {0}.',
  'the FIN-FSA register for payment services providers': 'le registre FIN-FSA des prestataires de services de paiement',
  'Please read the {0} carefully before continuing to authentication.': 'Veuillez lire attentivement les {0} avant de poursuivre l\'authentification.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Vous pouvez consulter les conditions et informations complémentaires sur le service Enable Banking API à tout moment à partir de {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Les informations et mises à jour sur le service ou l\'activité frauduleuse ou correspondante menaçant l\'utilisation sécurisée du service vous seront communiquées par votre fournisseur de services numériques en collaboration avec Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Conditions d\'utilisation',
  'Last updated:': 'Dernière mise à jour:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Veuillez lire attentivement ces conditions d\'utilisation avant d\'utiliser Enable Banking API.',
  'Description of the service': 'Descriptif du service',
  'Processing of personal data': 'Traitement des données personnelles',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking traite les données personnelles uniquement dans le but et dans la mesure où le service Enable Banking API peut fonctionner pour vous.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Lors du traitement des données personnelles « Enable Banking API » s\'engage à respecter la législation relative au traitement des données personnelles et à la protection des données, ainsi que les autres législations applicables et notamment le Règlement général de l\'UE sur la protection des données (UE 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Le contenu plus détaillé du traitement des données personnelles, y compris :',
  '(a) the nature and purpose of the processing;': '(a) la nature et la finalité du traitement ;',
  '(b) the type of personal data and categories of data subjects;': '(b) le type des données à caractère personnel et les catégories de personnes concernées ;',
  '(c) the applicable data protection measures;': '(c) les mesures de protection des données applicables ;',
  '(d) the customer’s rights and responsibilities;': '(d) les droits et responsabilités du client ;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'et (e) l\'objet et la durée du traitement des données personnelles seront décrits plus en détail dans la Politique de confidentialité.',
  'Links to other websites': 'Liens vers d\'autres sites',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking peut contenir des liens ou vous rediriger vers des sites Web ou des services tiers qui ne sont pas détenus ou contrôlés par la Société, y compris, mais sans s\'y limiter, les sites Web ou services des ASPSP.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'La Société n\'a aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques de tout site Web ou service tiers, et n\'assume aucune responsabilité pour ceux-ci.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Vous reconnaissez et acceptez en outre que la Société ne sera pas responsable, directement ou indirectement, de tout dommage ou perte causé ou présumé avoir été causé par ou en relation avec l\'utilisation ou la confiance accordée à un tel contenu, biens ou services disponibles sur ou par l\'intermédiaire de tels sites Web ou services.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Nous vous conseillons vivement de lire les conditions générales et les politiques de confidentialité de tout site Web ou service tiers que vous visitez.',
  'No warranty': 'Aucune garantie',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Sans limiter ce qui précède, ni la Société ni aucun des fournisseurs de la société ne fait aucune représentation ou garantie d\'aucune nature que ce soit, expresse ou implicite :',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) quant au fonctionnement ou à la disponibilité du Service, ou aux informations, contenus et matériels ou produits qui y sont inclus ;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) que le Service sera ininterrompu ou exempt d\'erreurs ;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) quant à l\'exactitude, la fiabilité ou l\'actualité de toute information ou contenu fourni par le biais du Service.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Certaines juridictions n\'autorisent pas l\'exclusion de certains types de garanties ou de limitations sur les droits statutaires applicables d\'un consommateur, de sorte que certaines ou toutes les exclusions et limitations ci-dessus peuvent ne pas s\'appliquer à vous.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Mais dans un tel cas, les exclusions et limitations énoncées dans la présente section seront appliquées dans la plus grande mesure applicable en vertu de la loi applicable.',
  'Changes': 'Changements',
  'The Company reserves the right to modify or replace the Service at any time.': 'La Société retient le droit de modifier ou de remplacer le Service à tout moment.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Si une révision est importante, la Société fera des efforts raisonnables pour vous informer avant que de nouvelles conditions n\'entrent en vigueur avec d\'autres fournisseurs de services numériques.',
  'You can also check any updates from this page.': 'Vous pouvez également vérifier les mises à jour à partir de cette page.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'En continuant à accéder ou à utiliser le Service après l\'entrée en vigueur de ces révisions, vous acceptez d\'être lié par les conditions révisées.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Si vous n\'acceptez pas les nouvelles conditions, en tout ou en partie, veuillez cesser d\'utiliser le Service.',
  'Termination': 'Résiliation',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Si pour une raison quelconque, vous ne souhaitez plus utiliser le Service pour partager vos données de comptes de paiement avec d\'autres services numériques, veuillez {0}.',
  'visit the Data Sharing Consents page': 'visitez la page des Consentements de Partage de Données',
  'Upon termination, your right to use the Service will cease immediately.': 'En cas de résiliation, votre droit d\'utiliser le Service cessera immédiatement.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Nous pouvons résilier ou suspendre votre accès immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit.',
  'Governing law': 'Droit applicable',
  'The laws of Finland shall govern your use of the Service.': 'Les lois de la Finlande régissent votre utilisation du Service.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Si vous êtes un consommateur de l\'Union européenne, vous bénéficierez de toutes les dispositions impératives de la loi du pays dans lequel vous résidez.',
  'Disputes resolution': 'Résolution des litiges',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Si vous avez des inquiétudes ou des différends concernant le Service, il vous est d\'abord demandé d\'essayer de résoudre le différend de manière informelle en contactant la Société.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Les utilisateurs consommateurs en Finlande ont également le droit d\'être en contact avec la commision des litiges consommateur, Hämeentie 3, P.O. Boîte postale 306, 00531 Helsinki, tél. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Les problèmes relatives à l\'entreprise doivent être adressées à l\'autorité de tutelle.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Les coordonnées de la FIN-FSA sont : Snellmaninkatu 6, 00100 Helsinki, Finlande, tél. +358 9 183 51 (standard) ; www.finanssivalvonta.fi.',
  'Contact us': 'Nous contacter',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Si vous avez des questions sur ces conditions d\'utilisation, vous pouvez nous contacter par e-mail à l\'adresse {0}.',
  // Backend messages
  '10 digit client number': 'Numéro de client à 10 chiffres',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': 'Numéro d\'organisation à 10 chiffres (ou numéro personnel à 12 chiffres si l\'entreprise est une entreprise individuelle)',
  '10 digit organization number.': 'Numéro d\'organisation à 10 chiffres.',
  '17 digit card number (starting with 6703)': 'Numéro de carte à 17 chiffres (commençant par 6703)',
  '6 digit Handelsbanken customer number': 'Numéro de client Handelsbanken à 6 chiffres',
  '8 digits CVR number': 'Numéro CVR à 8 chiffres',
  '9 digits Organisasjonsnummer': 'Numéro d\'organisation à 9 chiffres',
  'Agreement number': 'Numéro d\'agrément',
  'Agreement number for Nordea Corporate Netbank': 'Numéro de contrat pour Nordea Corporate Netbank',
  'Bank user ID': 'Identifiant d\'utilisateur de la banque',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Code d\'identité de l\'entreprise (Y-tunnus) au format 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Code d\'identification de l\'entreprise qui s\'affiche dans les paramètres de la banque Internet Entreprise',
  'Client ID': 'Identité du client',
  'Client number': 'Numéro client',
  'Company ID': 'Identifiant de l\'entreprise',
  'Company number': 'Numéro d\'entreprise',
  'Corporate ID': 'Identifiant d\'entreprise',
  'Corporate registration ID': 'ID d\'enregistrement d\'entreprise',
  'Currency code': 'Code de devise',
  'Digit card number': 'Numéro de carte numérique',
  'Disposer': 'Broyeur',
  'E-mail': 'E-mail',
  'E-mail used for authenticating to Holvi': 'E-mail utilisé pour l\'authentification auprès de Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Soit l\'identifiant SEB à 14 chiffres de l\'entreprise, soit le numéro d\'organisation à 10 chiffres',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (sans numéro de sous-compte) : numéro d\'agence à 3 chiffres + numéro de client à 7 chiffres',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Pour les entreprises suédoises, il s\'agit de leur numéro d\'organisation ou numéro SHB, et pour les entreprises individuelles (Enskild firma), il s\'agit de leur numéro personnel, composé tous de 10 chiffres.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'Carte d\'identité, NIE, passeport',
  'Login ID used in MyBRD aplications': 'ID de connexion utilisé dans les applications MyBRD',
  'Login Name': 'Identifiant',
  'Mandate ID': 'Identifiant du mandat',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Numéro d\'identité national norvégien au format JJMMAAXXXXX (11 chiffres)',
  'Online banking PIN': 'NIP bancaire en ligne',
  'Online banking Password': 'Mot de passe bancaire en ligne',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'ID d\'organisation au format TBxxxxx ou TXxxxxx',
  'Organisation number': 'Numéro d\'organisation',
  'PIN': 'PIN',
  'Password': 'Mot de passe',
  'Password for Aktia Internet bank': 'Mot de passe de la banque Internet Aktia',
  'Password used for authenticating to Holvi': 'Mot de passe utilisé pour l\'authentification auprès de Holvi',
  'Personal ID': 'identifiant personnel',
  'Personal ID number': 'Numéro d\'identification personnel',
  'Personal code': 'Code personnel',
  'Personal code of the user': 'Code personnel de l\'utilisateur',
  'Personal signatory number': 'Numéro de signataire personnel',
  'Personal username for online banking': 'Nom d\'utilisateur personnel pour les services bancaires en ligne',
  'Postbank ID': 'Identifiant de la banque postale',
  'Social security number (Personnummer)': 'Numéro de sécurité sociale (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Numéro de sécurité sociale au format AAAAMMDXXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Numéro de sécurité sociale suédois au format AAAMMDDXXXX',
  'The IBAN of the user authenticating': 'L\'IBAN de l\'utilisateur qui s\'authentifie',
  'The currency code': 'Le code de la devise',
  'The user ID of the user authenticating': 'L\'ID utilisateur de l\'utilisateur qui s\'authentifie',
  'Unique personal ID number': 'Numéro d\'identification personnel unique',
  'User ID': 'ID de l\'utilisateur',
  'User ID for Aktia Internet bank': 'Identifiant de la banque Internet Aktia',
  'User ID for Nordea Corporate Netbank': 'ID utilisateur pour Nordea Corporate Netbank',
  'User ID for online banking': 'ID utilisateur pour les services bancaires en ligne',
  'User ID in the format XX000000': 'ID utilisateur au format XX000000',
  'User ID used to log in to the bank': 'Identifiant utilisé pour se connecter à la banque',
  'Username': 'Nom d\'utilisateur',
  'Username used to log in to the bank': 'Nom d\'utilisateur utilisé pour se connecter à la banque',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'Nom d\'utilisateur WebSSO'
}
