<template>
  <div class="loading-content">
    <div class="text-center">
      <Spinner v-if="loading" class="mx-auto" />
      <div class="text-dark">{{ message }}</div>
      <div class="text-danger">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { i18n } from '../i18n.js'
import Spinner from '../components/elements/Spinner.vue'
import BaseService from '../services/BaseService.js'
export default {
  name: 'Home',
  components: {
    Spinner
  },
  data () {
    return {
      loading: true,
      message: '',
      error: ''
    }
  },
  async mounted () {
    try {
      const r = await BaseService.getNextPage()
      const redirectUrl = r.data.redirect_url
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
    } catch (e) {
      this.error = i18n.t('Authentication session has expired')
      this.loading = false
    }
  }
}
</script>

<style>
.loading-content {
  padding-top: 20vh;
}
</style>
