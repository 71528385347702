module.exports = {
  // General
  'About {appName}': 'Over {appName}',
  'About Enable Banking API': 'Over Enable Banking API',
  'Application description is not provided.': 'Beschrijving van de toepassing wordt niet verstrekt.',
  'Authenticate': 'Authenticeren',
  'Authentication method': 'authenticatie methode:',
  'Authentication session has expired': 'Authenticatiesessie is verlopen',
  'Cancel': 'Annuleren',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Klik op de knop {0} als u de autorisatie hebt bevestigd in de BankID-app.',
  'Continue authentication': 'Doorgaan met authenticatie',
  'Enter your OTP code with index {0}': 'Voer uw OTP-code in met index {0}',
  'Error during authentication:': 'Fout tijdens authenticatie:',
  'Error while updating authentication status': 'Fout tijdens het updaten van de authenticatiestatus',
  'Open the BankID app on this device': 'Open de BankID-app op dit apparaat',
  'OTP code': 'OTP-code',
  'Redirecting to your account servicing provider': 'Omleiden naar uw accountserviceprovider',
  'Returning to the authentication initiator': 'Terugkeren naar de authenticatie-initiator',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Start de BankID-app op uw mobiele apparaat en tik op de QR-codeknop in de app om de QR-code op deze pagina te scannen',
  'or': 'of',
  'Starting authorization': 'Autorisatie starten',
  'Start typing for filtering the list': 'Begin met typen om de lijst te filteren',
  'To continue please use your authentication app': 'Gebruik uw authenticatie-app om door te gaan',
  'Waiting for completion of authentication': 'Wachten op voltooiing van authenticatie',
  'Try again': 'Probeer het nog eens',
  'Submit': 'Indienen',
  'Unable to access server': 'Geen toegang tot server',
  'Unable to authenticate': 'Kan niet verifiëren',
  'Unable to continue authorization': 'Kan autorisatie niet voortzetten',
  'Unable to continue decoupled authorization': 'Kan ontkoppelde autorisatie niet voortzetten',
  'Unable to handle authentication status "{status}"': 'Kan verificatiestatus "{status}" niet verwerken',
  'Unknown error occurred': 'Er is een onbekende fout opgetreden',
  'You need to close this window': 'U moet dit venster sluiten',
  // Consent
  'Authentication is initiated by {0}.': 'Verificatie wordt gestart door {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Nadat u de authenticatie heeft voltooid, worden {0} gedeeld met de authenticatie-initiator.',
  'your payment accounts data': 'uw betaalrekeninggegevens',
  'the list of your payment accounts': 'de lijst met uw betaalrekeningen',
  'the list of your payment accounts and accounts balances': 'de lijst met uw betaalrekeningen en rekeningsaldi',
  'the list of your payment accounts and accounts transactions': 'de lijst met uw betaalrekeningen en rekeningtransacties',
  'the list of your payment accounts, accounts balances and transactions': 'de lijst met uw betaalrekeningen, rekeningsaldi en transacties',
  'By pressing {0} button you agree with the {1}.': 'Door op de knop {0} te drukken, gaat u akkoord met de {1}.',
  'terms of Enable Banking API service': 'voorwaarden van de Enable Banking API-service',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Druk op de knop {0} als u niet bereid bent uw betalingsaccountgegevens te delen.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API is een online dienst waarmee u uw betaalrekeninggegevens van een gekozen bank of een vergelijkbare organisatie (Account Servicing Payment Service Provider, de ASPSP) kunt gebruiken in andere digitale diensten.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Om uw betaalrekeninggegevens te gebruiken, moet u zich authenticeren in de ASPSP-service.',
  'The service was formerly known as Tilisy business API.': 'De service was voorheen bekend als Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Uw uitdrukkelijke toestemming voor het gebruik van de betaalrekeninggegevens wordt bijgehouden in Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Deze service registreert uw PSU-verificatietokens en toestemmings-ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Nadat u toestemming heeft gegeven in de ASPSP-service, opent Enable Banking API de toegang tot uw betaalrekeninggegevens zodat de digitale service die u gebruikt (d.w.z. de authenticatie-initiator) deze gegevens kan ophalen.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Uw betaalrekeninggegevens stromen door de Enable Banking API en worden daar niet geregistreerd.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API is eigendom van en wordt beheerd door Enable Banking Oy, gevestigd in Otakaari 5, 02150 Espoo, Finland, bedrijfs-ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Het bedrijf is een geregistreerde dienstverlener (Account Information Service Provider, AISP) en staat onder toezicht van de Finse financiële toezichthoudende autoriteit (de FIN-FSA).',
  'The registration details can be found from {0}.': 'De registratiegegevens zijn te vinden op {0}.',
  'the FIN-FSA register for payment services providers': 'het FIN-FSA-register voor betalingsdienstaanbieders',
  'Please read the {0} carefully before continuing to authentication.': 'Lees de {0} zorgvuldig door voordat u doorgaat met authenticatie.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'U kunt de voorwaarden en aanvullende informatie over de Enable Banking API-service op elk moment vanaf {0} bekijken.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Informatie en updates over service of frauduleuze of overeenkomstige activiteiten die het veilige gebruik van de service in gevaar brengen, worden door uw digitale serviceprovider samen met Enable Banking Oy aan u gecommuniceerd.',
  // Terms of Service
  'Terms of Service': 'Servicevoorwaarden',
  'Last updated:': 'Laatst bijgewerkt:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Lees deze servicevoorwaarden aandachtig door voordat u Enable Banking API gebruikt.',
  'Description of the service': 'Beschrijving van de dienst',
  'Processing of personal data': 'Verwerking van persoonsgegevens',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking verwerkt persoonsgegevens alleen voor het doel en voor zover de Enable Banking API-service voor u kan functioneren.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Bij het verwerken van persoonsgegevens verbindt Enable Banking API zich ertoe de wetgeving met betrekking tot de verwerking van persoonsgegevens en gegevensbescherming na te leven, evenals andere toepasselijke wetgeving en in het bijzonder de EU Algemene Verordening Gegevensbescherming (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'De meer gedetailleerde inhoud van de verwerking van persoonsgegevens, waaronder:',
  '(a) the nature and purpose of the processing;': '(a) de aard en het doel van de verwerking;',
  '(b) the type of personal data and categories of data subjects;': '(b) het soort persoonsgegevens en categorieën van betrokkenen;',
  '(c) the applicable data protection measures;': '(c) de toepasselijke gegevensbeschermingsmaatregelen;',
  '(d) the customer’s rights and responsibilities;': '(d) de rechten en verantwoordelijkheden van de klant;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'en (e) het doel en de duur van de verwerking van persoonsgegevens worden in meer detail beschreven in het privacybeleid.',
  'Links to other websites': 'Links naar andere websites',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking kan links bevatten of u doorverwijzen naar websites of diensten van derden die geen eigendom zijn van of beheerd worden door het Bedrijf, inclusief maar niet beperkt tot websites of diensten van ASPSP\'s.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Het bedrijf heeft geen controle over en aanvaardt geen verantwoordelijkheid voor de inhoud, het privacybeleid of de praktijken van websites of diensten van derden.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Verder erkent u en gaat u ermee akkoord dat het Bedrijf niet verantwoordelijk of aansprakelijk is, direct of indirect, voor enige schade of verlies veroorzaakt of beweerd te zijn veroorzaakt door of in verband met het gebruik van of het vertrouwen op dergelijke inhoud, goederen of diensten die beschikbaar zijn op of via dergelijke websites of diensten.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'We raden u ten zeerste aan om de algemene voorwaarden en het privacybeleid te lezen van websites of services van derden die u bezoekt.',
  'No warranty': 'Geen garantie',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Zonder het voorgaande te beperken, geeft noch het bedrijf, noch een van de leveranciers van het bedrijf enige verklaring of garantie van welke aard dan ook, expliciet of impliciet:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) met betrekking tot de werking of beschikbaarheid van de Service, of de informatie, inhoud en materialen of producten die daarop zijn opgenomen;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) dat de Dienst ononderbroken of foutloos zal zijn;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) met betrekking tot de nauwkeurigheid, betrouwbaarheid of actualiteit van informatie of inhoud die via de Service wordt verstrekt.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Sommige rechtsgebieden staan ​​de uitsluiting van bepaalde soorten garanties of beperkingen op toepasselijke wettelijke rechten van een consument niet toe, dus sommige of alle bovenstaande uitsluitingen en beperkingen zijn mogelijk niet op u van toepassing.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Maar in een dergelijk geval zullen de uitsluitingen en beperkingen die in deze sectie worden uiteengezet, worden toegepast voor zover dit afdwingbaar is onder de toepasselijke wetgeving.',
  'Changes': 'Veranderingen',
  'The Company reserves the right to modify or replace the Service at any time.': 'Het Bedrijf behoudt zich het recht voor om de Dienst op elk moment te wijzigen of te vervangen.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Als een herziening wezenlijk is, zal het Bedrijf redelijke inspanningen leveren om u op de hoogte te stellen voordat nieuwe voorwaarden van kracht worden, samen met andere digitale dienstverleners.',
  'You can also check any updates from this page.': 'U kunt op deze pagina ook eventuele updates bekijken.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Door de Service te blijven openen of gebruiken nadat deze herzieningen van kracht zijn geworden, gaat u ermee akkoord gebonden te zijn aan de herziene voorwaarden.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Als u niet akkoord gaat met de nieuwe voorwaarden, geheel of gedeeltelijk, stop dan met het gebruik van de Service.',
  'Termination': 'Beëindiging',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Als u om welke reden dan ook geen gebruik meer wilt maken van de Service om uw betaalrekeninggegevens te delen met andere digitale diensten, bezoek dan {0}.',
  'visit the Data Sharing Consents page': 'bezoek de pagina voor Gegevensuitwisselings Toestemming',
  'Upon termination, your right to use the Service will cease immediately.': 'Bij beëindiging vervalt uw recht om de Dienst te gebruiken onmiddellijk.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'We kunnen uw toegang onmiddellijk beëindigen of opschorten, zonder voorafgaande kennisgeving of aansprakelijkheid, om welke reden dan ook.',
  'Governing law': 'Toepasselijk recht',
  'The laws of Finland shall govern your use of the Service.': 'De wetten van Finland zijn van toepassing op uw gebruik van de Service.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Als u een consument van de Europese Unie bent, profiteert u van alle dwingende bepalingen van de wet van het land waarin u woont.',
  'Disputes resolution': 'Geschillenbeslechting:',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Als u zich zorgen maakt of een geschil heeft over de Service, wordt u in eerste instantie gevraagd om het geschil informeel op te lossen door contact op te nemen met het Bedrijf.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'De consumentengebruikers in Finland hebben ook het recht om contact op te nemen met de Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'De bedrijfsgerelateerde zaken worden gericht aan de toezichthoudende autoriteit.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'De contactgegevens van de FIN-FSA zijn: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (telefooncentrale); www.finanssivalvonta.fi.',
  'Contact us': 'Neem contact met ons op',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Als u vragen heeft over deze servicevoorwaarden, kunt u per e-mail contact met ons opnemen op {0}.',
  // Backend messages
  '10 digit client number': 'Klantnummer van 10 cijfers',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-cijferig organisatienummer (of 12-cijferig persoonlijk nummer als het bedrijf een eenmanszaak is)',
  '10 digit organization number.': 'Organisatienummer van 10 cijfers',
  '17 digit card number (starting with 6703)': '17-cijferig kaartnummer (beginnend met 6703)',
  '6 digit Handelsbanken customer number': '6-cijferig klantnummer van Handelsbanken',
  '8 digits CVR number': '8-cijferig CVR-nummer',
  '9 digits Organisasjonsnummer': '9-cijferig Organisasjonsnummer',
  'Agreement number': 'Overeenkomstnummer',
  'Agreement number for Nordea Corporate Netbank': 'Overeenkomstnummer voor Nordea Corporate Netbank',
  'Bank user ID': 'Bankgebruikers-ID',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Bedrijfsidentiteitscode (Y-tunnus) in 1234567-8-formaat',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Zakelijke identiteitscode die wordt weergegeven in de instellingen voor zakelijk internetbankieren',
  'Client ID': 'Klant identificatie',
  'Client number': 'Client nummer',
  'Company ID': 'Bedrijfs ID',
  'Company number': 'Bedrijfsnummer',
  'Corporate ID': 'Bedrijfs-ID',
  'Corporate registration ID': 'Bedrijfsregistratie-ID',
  'Currency code': 'Valuta code',
  'Digit card number': 'Cijferkaartnummer',
  'Disposer': 'Ontdoener',
  'E-mail': 'E-mail',
  'E-mail used for authenticating to Holvi': 'E-mailadres gebruikt voor authenticatie bij Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Ofwel de 14-cijferige SEB-identificatiecode voor het bedrijf, ofwel het 10-cijferige organisatienummer',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (zonder subrekeningnummer): 3-cijferig filiaalnummer + 7-cijferig klantnummer',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Voor Zweedse bedrijven is dit hun organisatienummer of SHB-nummer, en voor eenmanszaken (Enskild firma) is dit hun persoonlijke nummer, dat allemaal uit 10 cijfers bestaat.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, paspoort',
  'Login ID used in MyBRD aplications': 'Login-ID gebruikt in MyBRD-applicaties',
  'Login Name': 'Inlog naam',
  'Mandate ID': 'Mandaat-ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Noors nationaal identiteitsnummer in het formaat DDMMYYXXXXX (11 cijfers)',
  'Online banking PIN': 'Pincode voor internetbankieren',
  'Online banking Password': 'Wachtwoord voor online bankieren',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Organisatie-ID in het formaat TBxxxxx of TXxxxxx',
  'Organisation number': 'Organisatienummer',
  'PIN': 'PIN',
  'Password': 'Wachtwoord',
  'Password for Aktia Internet bank': 'Wachtwoord voor Aktia internetbank',
  'Password used for authenticating to Holvi': 'Wachtwoord gebruikt voor authenticatie bij Holvi',
  'Personal ID': 'Persoonlijk ID',
  'Personal ID number': 'Persoonlijk ID-nummer',
  'Personal code': 'Persoonlijke code',
  'Personal code of the user': 'Persoonlijke code van de gebruiker',
  'Personal signatory number': 'Persoonlijk ondertekenaarnummer',
  'Personal username for online banking': 'Persoonlijke gebruikersnaam voor online bankieren',
  'Postbank ID': 'Postbank-ID',
  'Social security number (Personnummer)': 'Burgerservicenummer (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Burgerservicenummer in de notatie JJJJMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Zweeds burgerservicenummer in de notatie JJJJMMDDXXXX',
  'The IBAN of the user authenticating': 'Het IBAN van de gebruiker die authenticeert',
  'The currency code': 'De valutacode',
  'The user ID of the user authenticating': 'De gebruikers-ID van de gebruiker die authenticeert',
  'Unique personal ID number': 'Uniek persoonlijk ID-nummer',
  'User ID': 'Gebruikersnaam',
  'User ID for Aktia Internet bank': 'Gebruikers-ID voor Aktia internetbank',
  'User ID for Nordea Corporate Netbank': 'Gebruikers-ID voor Nordea Corporate Netbank',
  'User ID for online banking': 'Gebruikers-ID voor online bankieren',
  'User ID in the format XX000000': 'Gebruikers-ID in het formaat XX000000',
  'User ID used to log in to the bank': 'Gebruikers-ID waarmee u inlogt bij de bank',
  'Username': 'Gebruikersnaam',
  'Username used to log in to the bank': 'Gebruikersnaam waarmee u inlogt bij de bank',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO-gebruikersnaam'
}
