module.exports = {
  // General
  'About {appName}': '{appName}:sta',
  'About Enable Banking API': 'Enable Banking API:sta',
  'Application description is not provided.': 'Sovelluksen kuvausta ei toimiteta.',
  'Authenticate': 'Tunnistaudu',
  'Authentication method': 'Todennus tapa',
  'Authentication session has expired': 'Istunto on vanhentunut',
  'Cancel': 'Peruuta',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Paina {0} painiketta, jos olet vahvistanut valtuutuksen BankID-sovelluksessa.',
  'Continue authentication': 'Jatka tunnistautumista',
  'Enter your OTP code with index {0}': 'Anna avaintunnusta {0} vastaava turvaluku',
  'Error during authentication:': 'Virhe tunnistautumisen aikana:',
  'Error while updating authentication status': 'Virhe päivitettäessä tunnistautumisen tilaa',
  'Open the BankID app on this device': 'Avaa BankID-sovellus tällä laitteella',
  'OTP code': 'Turvaluku',
  'Redirecting to your account servicing provider': 'Uudelleenohjaus tilitietopalveluntarjoajaan',
  'Returning to the authentication initiator': 'Palataan tunnistautumisen aloittajalle',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Käynnistä BankID-sovellus mobiililaitteellasi ja paina sovelluksen QR-koodi -painiketta skannataksesi tällä sivulla oleva QR-koodi',
  'or': 'tai',
  'Starting authorization': 'Aloitetaan tunnistautuminen',
  'Start typing for filtering the list': 'Aloita kirjoittaminen luettelon suodattamiseksi',
  'To continue please use your authentication app': 'Jatka käyttämällä tunnistautumissovellustasi',
  'Waiting for completion of authentication': 'Odotetaan todennuksen valmistumista',
  'Try again': 'Yritä uudelleen',
  'Submit': 'Lähetä',
  'Unable to access server': 'Palvelinta ei voi käyttää',
  'Unable to authenticate': 'Tunnistautuminen epäonnistui',
  'Unable to continue authorization': 'Valtuutusta ei voi jatkaa',
  'Unable to continue decoupled authorization': 'Irrotettua tunnistusta ei voida jatkaa',
  'Unable to handle authentication status "{status}"': 'Tunnistustilaa "{status}" ei voida käsitellä',
  'Unknown error occurred': 'Tapahtui tuntematon virhe',
  'You need to close this window': 'Sinun on suljettava tämä ikkuna',
  // Consent
  'Authentication is initiated by {0}.': 'Tunnistautumisen suorittaa {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Kun olet suorittanut tunnistautumisen, {0} tullaan jakamaan tunnistautumisen suorittajan kanssa.',
  'your payment accounts data': 'maksutilisi tieto',
  'the list of your payment accounts': 'lista maksutileistä',
  'the list of your payment accounts and accounts balances': 'lista maksutileistä ja tilien saldot',
  'the list of your payment accounts and accounts transactions': 'lista maksutileistä ja tilitapahtumat',
  'the list of your payment accounts, accounts balances and transactions': 'lista maksutileistä, tilien saldot ja tilitapahtumat',
  'By pressing {0} button you agree with the {1}.': 'Painamalla {0} painiketta hyväksyt samalla {1}.',
  'terms of Enable Banking API service': 'Enable Banking API ratkaisun ehdot',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Paina {0} painiketta jos et halua jakaa, maksutilisi tietoja.',
  // Payment consent
  'Payment details': 'Maksutiedot',
  'Payment ID provided by the application: {0}': 'Sovelluksen antama maksutunnus: {0}',
  'Account number where to transfer funds: {0}': 'Tilinumero, johon varat siirretään: {0}',
  'Payee name in the payment: {0}': 'Maksunsaajan nimi maksussa: {0}',
  'Payee country: {0}': 'Maksunsaajan maa: {0}',
  'Payment amount: {0}': 'Maksun summa: {0}',
  'Message or reference number in the payment: {0}': 'Viesti tai viitenumero maksussa: {0}',
  'Payment is initiated by {0}.': 'Maksun suorittaa {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'Kun olet suorittanut todennuksen, tiliäsi ylläpitävä maksupalveluntarjoaja pyytää maksusuostumustasi.',
  'terms of service': 'käyttöehdot',
  'Press {0} button if you are not willing to complete this payment.': 'Paina {0} painiketta jos et halua suorittaa tätä maksua.',
  'Select Payment Account': 'Valitse maksutili',
  'Confirm': 'Vahvistaa',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API mahdollistaa valitsemassasi maksulaitoksessa tai pankissa (Account Servicing Payment Service Provider, ASPSP) olevan maksutilisi tietojen käyttämisen muissa sähköisissä palveluissa.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Maksutilitietojesi käyttäminen edellyttää sinulta tunnistautumista kyseisen maksulaitoksen tai pankin palvelussa.',
  'The service was formerly known as Tilisy business API.': 'Palvelu tunnettiin aiemmin nimellä Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Enable Banking API ylläpitää antamaasi nimenomaista suostumusta maksutilitietojesi käyttämiseksi.',
  'This service registers your PSU authentication tokens and consent ID.': 'Palvelu rekisteröi tunnistautumista koskevat yksilöintitiedot.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Kun olet antanut suostumuksesi maksutilitietojesi käyttöön maksulaitoksen tai pankin palvelussa, tiedot voidaan hakea muun sähköisen palvelun käytettäväksi.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Maksutilitietosi siirtyvät Enable Banking API:n kautta, mutta palvelu ei tallenna näitä tietoja.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API -palvelun omistaa ja sitä hallinnoi Enable Banking Oy, Y-tunnus 2988499-7, Otakaari 5, 02150 Espoo.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Yritys on rekisteröitynyt tilitietopalvelujen tarjoajaksi (Account Information Service Provider, AISP) ja sen toimintaa valvoo Finanssivalvonta.',
  'The registration details can be found from {0}.': 'Rekisteröintiä koskevat tiedot voi tarkistaa {0}.',
  'the FIN-FSA register for payment services providers': 'Finanssivalvonnan internet-sivuilta',
  'Please read the {0} carefully before continuing to authentication.': 'Kehotamme sinua tutustumaan {0} ja tietosuojaselosteeseemme ennen tunnistautumista maksulaitoksen tai pankin palvelussa.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Käyttöehdot ja muut mahdolliset palvelun käyttöön liittyvät tiedot ovat aina luettavissa {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Tiedot palvelun mahdollisista päivityksistä tai turvallisuutta uhkaavista väärinkäytöstilanteista annetaan sinulle suoraan käyttämäsi sähköisen palvelun kautta.',
  // Terms of Service
  'Terms of Service': 'Käyttöehdot',
  'Last updated:': 'Viimeksi päivitetty:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Lue nämä käyttöehdot huolellisesti ennen Enable Banking API: n käyttöä.',
  'Description of the service': 'Palvelun kuvaus',
  'Processing of personal data': 'Henkilötietojen käsittely',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking käsittelee henkilötietoja vain siihen tarkoitukseen ja siinä laajuudessa, että Enable Banking API palvelu voi toimia sinulle.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Henkilötietojen käsittelyssä Enable Banking API sitoutuu noudattamaan henkilötietojen käsittelyä ja tietosuojaa sekä muita sovellettavaa lainsäädäntöä ja erityisesti EU yleistä tietosuoja-asetusta (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Yksityiskohteisempi sisältö henkilötietojen käsittelystä, sisältää:',
  '(a) the nature and purpose of the processing;': '(a) tietojen käsittelyn luonne ja tarkoitus;',
  '(b) the type of personal data and categories of data subjects;': '(b) henkilötietojen tyyppi ja rekisteröityjen tietojen luokat;',
  '(c) the applicable data protection measures;': '(c) sovellettavat tietosuojatoimenpiteet;',
  '(d) the customer’s rights and responsibilities;': '(d) asiakkaan oikeudet ja velvollisuudet;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'ja (e) henkilötietojen käsittelyn kohde ja kesto on kuvattu tarkemmin tietosuojakäytäntö dokumentista.',
  'Links to other websites': 'Linkkejä muille sivuille',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking:ssa voi olla linkkejä tai se voi ohjata sinut kolmannen osapuolen verkkosivustoille tai palveluihin, jotka eivät ole yhteiön omistuksessa tai hallinnassa, mukaan lukien mutta ei rajoittuen ASPSPs verkkosivuille tai palveluihin.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Yhtiöllä ei valvo, eikä ota mitään vastuuta, sisältö, tietosuojakäytäntöjä tai käytäntöjä kolmannen osapuolen verkkosivustoista tai palveluista.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Lisäksi käyttäjä ymmärtää ja hyväksyy, että yhtiö ei ole vastuussa suoraan tai epäsuorasti, mistään vahingosta tai menetyksestä, jotka väitetään johtuvan siihen tai sen käyttöön liittyvistä tai siitä riippuvista sisällöistä, tavaroista tai palveluista, jotka ovat saatavilla tällaisten sivustojen tai palveluiden kautta, suoraan tai epäsuorasti.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Kehotamme lukemaan käyttämäsi kolmannen osapuolen verkkosivujen tai palvelujen ehdot ja tietosuojakäytännöt.',
  'No warranty': 'Ei takuuta',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Rajoittamatta edellä olevaa, yritys tai kukaan sen tarjoaja ei tarjoa minkäänlaista suoraa tai epäsuoraa vakuutusta tai takuuta:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) toiminnan tai palvelun saatavuuteen, tai tietoihin, sisältöihin ja materiaaleihin tai tuotteisiin mukaan lukien siihen;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) että Palvelu on käytettävissä keskeytyksettä tai virheettömästi;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii), kuten palvelun kautta saatavan tiedon tai sisällön oikeellisuudesta, luotettavuudesta, tai valuutoista',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Joidenkin maiden lainsäädäntö ei salli tietyntyyppisiä takuita tai niiden rajoituksia kuluttajaan sovellettaviin lakisääteisiin oikeuksiin, joten jotkin tai kaikki edellä mainitut poikkeukset ja rajoitukset eivät välttämättä koske sinua.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Mutta tällaisessa tapauksessa näissä ehdoissa asetetut poikkeukset ja rajoitukset,  sovelletaan kaikilta täytäntöönpanokelpoisilta osin sovellettavan lain mukaan.',
  'Changes': 'Muutokset',
  'The Company reserves the right to modify or replace the Service at any time.': 'Yhtiö pidättää oikeuden muuttaa tai korvata Palvelua milloin tahansa.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Jos muutos on olennainen, Yhtiö pyrkii kohtuullisesti ilmoittamaan sinulle ennen uusien ehtojen voimaantuloa yhdessä muiden digitaalisten palvelujen tarjoajien kanssa.',
  'You can also check any updates from this page.': 'Voit myös tarkistaa päivitykset tältä sivulta.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Jatkamalla Palvelun käyttöä näiden muutosten voimaantulon jälkeen, sitoudut noudattamaan muutettuja ehtoja.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Jos et hyväksy uusia ehtoja, kokonaan tai osittain, lopeta Palvelun käyttö.',
  'Termination': 'Päättäminen',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Jos et jostain syystä enää halua käyttää Palvelua jakamaan maksutilejä koskevia tietojasi muiden digitaalisten palveluiden kanssa, ole hyvä ja {0}.',
  'visit the Data Sharing Consents page': 'vieraile Tietojen Jakamisen Suostumukset sivulla',
  'Upon termination, your right to use the Service will cease immediately.': 'Sopimuksen päättyessä asiakkaan oikeus käyttää Palvelua lakkaa välittömästi.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Voimme lopettaa tai keskeyttää käyttäjän pääsyn välittömästi, ilman ennakkoilmoitusta tai vastuuta, mistä syystä tahansa.',
  'Governing law': 'Sovellettava lainsäädäntö',
  'The laws of Finland shall govern your use of the Service.': 'Palvelun käyttöä säätelevät Suomen lait.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Jos olet Euroopan unionin kuluttaja, voit hyötyä kaikista sen maan lakisääteisistä säännöksistä, jossa asut.',
  'Disputes resolution': 'Riitatilanteiden ratkaisu',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Jos sinulla on huolta tai riitaa Palvelusta, sinua pyydetään ensin yrittämään ratkaisemaan kiista epävirallisesti ottamalla yhteyttä yritykseen.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Suomessa kuluttajajien on oikeus olla yhteydessä Kuluttajariitalautakunta Hämeentie 3, P.O. Box 306, 00531 Helsinki, puh. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Yhtiöön liittyvät kysymykset tulee osoittaa valvovalle viranomaiselle.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Finanssivalvonnan yhteystiedot: Snellmaninkatu 6, 00100 Helsinki, Suomi, puh. +358 9 183 51 (vaihde); www.finanssivalvonta.fi.',
  'Contact us': 'Ota meihin yhteyttä',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Jos sinulla on kysyttävää näistä käyttöehdoista, voit ottaa yhteyttä sähköpostitse osoitteeseen {0}.',
  // Backend messages
  '10 digit client number': '10-numeroinen asiakasnumero',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-numeroinen organisaationumero (tai 12-numeroinen henkilötunnus, jos yritys on yksityinen elinkeinonharjoittaja)',
  '10 digit organization number.': '10-numeroinen organisaation numero',
  '17 digit card number (starting with 6703)': '17-numeroinen kortin numero (alkaen numerosta 6703)',
  '6 digit Handelsbanken customer number': '6-numeroinen Handelsbankenin asiakasnumero',
  '8 digits CVR number': '8-numeroinen CVR-numero',
  '9 digits Organisasjonsnummer': '9-numeroinen Organisasjonsnummer',
  'Agreement number': 'Sopimusnumero',
  'Agreement number for Nordea Corporate Netbank': 'Nordea Corporate Netbank sopimuksen numero',
  'Bank user ID': 'Pankin käyttäjätunnus',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Yritystunnus (Y-tunnus) muodossa 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Yritystunnus, joka näkyy yrityksen Internet-pankin asetuksissa',
  'Client ID': 'Asiakastunnus',
  'Client number': 'Asiakasnumero',
  'Company ID': 'Y-tunnus',
  'Company number': 'Yrityksen numero',
  'Corporate ID': 'Yritystunnus',
  'Corporate registration ID': 'Yrityksen rekisteröintitunnus',
  'Currency code': 'Valuutta koodi',
  'Digit card number': 'Numerokortin numero',
  'Disposer': 'Hävittäjä',
  'E-mail': 'Sähköposti',
  'E-mail used for authenticating to Holvi': 'Sähköposti, jota käytetään todentamiseen Holville',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Joko yrityksen 14-numeroinen SEB-tunnus tai 10-numeroinen organisaation numero',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (ilman alatilinumeroa): 3-numeroinen konttorin numero + 7-numeroinen asiakasnumero',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Ruotsalaisille yrityksille tämä on organisaation numero tai SHB-numero, ja yksinyrittäjille (Enskild firma) henkilökohtainen numero, jotka kaikki ovat 10 numeroa.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'Henkilöllisyystodistus, NIE, passi',
  'Login ID used in MyBRD aplications': 'MyBRD-sovelluksissa käytetty kirjautumistunnus',
  'Login Name': 'Kirjautumisnimi',
  'Mandate ID': 'Toimeksiantotunnus',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norjan kansallinen henkilötunnus muodossa DDMMYYXXXXX (11 numeroa)',
  'Online banking PIN': 'Verkkopankin PIN-koodi',
  'Online banking Password': 'Verkkopankin salasana',
  'Organisation ID in the format TBxxxxx, TXxxxxx, TUxxxxx or NUxxxxx': 'Organisaation tunnus muodossa TBxxxxx tai TXxxxxx tai TUxxxxx tai NUxxxxx',
  'Organisation number': 'Organisaation numero',
  'PIN': 'PIN-koodi',
  'Password': 'Salasana',
  'Password for Aktia Internet bank': 'Salasana Aktian Internet-pankille',
  'Password used for authenticating to Holvi': 'Salasana, jota käytetään todentamiseen Holville',
  'Personal ID': 'Henkilötunnus',
  'Personal ID number': 'Henkilötunnus',
  'Personal code': 'Henkilökohtainen koodi',
  'Personal code of the user': 'Käyttäjän henkilökohtainen koodi',
  'Personal signatory number': 'Henkilökohtainen allekirjoitusnumero',
  'Personal username for online banking': 'Henkilökohtainen käyttäjätunnus verkkopankkiin',
  'Postbank ID': 'Postipankin tunnus',
  'Resource owner authentication failed': 'Resurssin omistajan todennus epäonnistui',
  'Social security number (Personnummer)': 'Sosiaaliturvatunnus (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Sosiaaliturvatunnus muodossa VVVVKKPPXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Ruotsalainen henkilötunnus muodossa VVVVKKPPXXXX',
  'The IBAN of the user authenticating': 'Todennuksen suorittavan käyttäjän IBAN',
  'The currency code': 'Valuuttakoodi',
  'The user ID of the user authenticating': 'Todennusta tekevän käyttäjän käyttäjätunnus',
  'Unique personal ID number': 'Ainutlaatuinen henkilötunnus',
  'User ID': 'Käyttäjätunnus',
  'User ID for Aktia Internet bank': 'Käyttäjätunnus Aktian Internet-pankille',
  'User ID for Nordea Corporate Netbank': 'Nordea Corporate Netbank käyttäjätunnus',
  'User ID for online banking': 'Käyttäjätunnus verkkopankkia varten',
  'User ID in the format XX000000': 'Käyttäjätunnus muodossa XX000000',
  'User ID used to log in to the bank': 'Pankkiin kirjautumiseen käytetty käyttäjätunnus',
  'Username': 'Käyttäjätunnus',
  'Username used to log in to the bank': 'Pankkiin kirjautumiseen käytetty käyttäjätunnus',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO-käyttäjänimi'
}
