import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/Home.vue'
import AIS from './pages/AIS.vue'
import PIS from './pages/PIS.vue'
import Terms from './pages/Terms.vue'
import Privacy from './pages/Privacy.vue'

Vue.use(Router)

const pageTitle = process.env.VUE_APP_TITLE || 'Enable Banking'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            title: pageTitle,
            description: ''
          }
        },
        {
          path: 'terms',
          name: 'terms',
          component: Terms,
          meta: {
            title: 'Terms of Enable Banking API Service',
            description: ''
          }
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: Privacy,
          meta: {
            title: 'Privacy policy of Enable Banking API Service',
            description: ''
          }
        }
      ]
    },
    {
      path: '/ais',
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: '',
          name: 'AIS',
          component: AIS,
          meta: {
            title: pageTitle,
            description: ''
          }
        }
      ]
    },
    {
      path: '/pis',
      component: { render (c) { return c('router-view') } },
      children: [
        {
          path: '',
          name: 'PIS',
          component: PIS,
          meta: {
            title: pageTitle,
            description: ''
          }
        }
      ]
    }
  ],
  scrollBehavior (_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
