<template>
  <div>
    <div v-if="display && languages.length > 1">
      <a
        href="#"
        class="text-decoration-none text-uppercase text-dark ml-2"
        v-on:click.prevent="isShowModal = true"
        ><b>{{ $i18n.locale }}</b></a
      >
      <Modal
        :isShow="isShowModal"
        :isShowFooter="false"
        size="sm"
        v-on:close="isShowModal = false"
      >
        <div class="p-3">
          <a
            href="#"
            class="bg-hover text-decoration-none text-dark d-flex align-items-center"
            :class="[
              { 'bg-hover--active': language === $i18n.locale },
              { 'mb-1': index + 1 < languages.length },
            ]"
            v-for="(language, index) in languages"
            :key="`language-${language}`"
            v-on:click.prevent="setLanguage(language)"
          >
            <span class="d-block">{{ languageNames[language] }}</span>
          </a>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from './Modal.vue'

const LANGUAGES = {
  DA: 'Dansk',
  DE: 'Deutsch',
  EN: 'English',
  ES: 'Español',
  ET: 'Eesti',
  FI: 'Suomi',
  FR: 'Français',
  IS: 'Íslenska',
  IT: 'Italiano',
  LT: 'Lietuvių',
  LV: 'Latviešu',
  NL: 'Nederlands',
  NO: 'Norsk',
  PL: 'Polski',
  RU: 'Русский',
  SV: 'Svenska'
}

export default {
  data () {
    return {
      isShowModal: false,
      display: false,
      languageNames: LANGUAGES,
      languages: Object.keys(this.$i18n.messages)
    }
  },
  components: {
    Modal
  },
  beforeMount () {
    const urlLang = new URLSearchParams(window.location.search).get('locale')
    const lastLocale = window.localStorage.getItem('last-locale')
    const selectedLang = window.localStorage.getItem('selected-language')
    window.localStorage.removeItem('last-locale')
    const locale = urlLang && this.$i18n.messages[urlLang]
      ? urlLang
      : lastLocale && this.$i18n.messages[lastLocale]
        ? lastLocale
        : selectedLang && this.$i18n.messages[selectedLang] ? selectedLang : null
    if (locale) {
      this.$root.$i18n.locale = locale
      window.document.documentElement.setAttribute(
        'lang',
        this.$root.$i18n.locale.toLocaleLowerCase()
      )
      this.display = true
    }
    // Delaying display to prevent blinking
    if (!this.display) {
      setTimeout(() => { this.display = true }, 1000)
    }
  },
  methods: {
    setLanguage (lang) {
      if (lang !== this.$i18n.locale) {
        window.localStorage.setItem('selected-language', lang)
        this.$root.$i18n.locale = lang
        window.document.documentElement.setAttribute(
          'lang',
          this.$root.$i18n.locale.toLocaleLowerCase()
        )
        // changing URL (if necessary)
        const url = new URL(window.location.href)
        const urlLang = url.searchParams.get('locale')
        if (urlLang && this.$i18n.messages[urlLang]) {
          url.searchParams.delete('locale')
          window.location.replace(url.href)
        }
      }
      this.isShowModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/variables";

.flag-icon{
  width: 2rem;
  height: 2rem;
}
.bg-hover{
  border-radius: $border-radius;
  padding: 0.5rem;
  cursor: pointer;
  &:hover, &--active{
    background-color: $gray-200;
  }
}
.language-select{
  max-width: 60px;
}
</style>
