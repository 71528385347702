<template>
  <form v-on:submit.prevent="onSubmit">
    <div
      class="form-inline justify-content-center mb-5"
      v-if="authOptions.length > 1"
    >
      <span class="mr-2">{{ $t('Authentication method') }}</span>
      <select
        class="form-control"
        v-model="authMethod"
      >
        <option
          v-for="opt in authOptions"
          :value="opt.method"
          :key="`auth-method-${opt.method.replace(/\s+/g, '-')}`"
        >{{ opt.title || opt.method }}</option>
      </select>
    </div>
    <div
      class="form mb-5"
      v-if="authCredentials && authCredentials.length"
    >
      <div
        class="form-group row justify-content-center"
        v-for="cred in authCredentials"
        :key="`auth-credential-${cred.name}`"
      >
        <div class="col-sm-6 col-lg-4">
          <div class="input-group">
            <input
              class="form-control"
              :disabled="cred.disabled"
              :name="`credential-${cred.name}`"
              :required="cred.required"
              :placeholder="`${ $t(cred.title || cred.name) }`"
              :type="cred.visible ? 'text' : 'password'"
              :pattern="cred.template"
              v-model="cred.value"
            >
            <div class="input-group-append">
              <span
                class="input-group-text px-2 py-1"
                role="button"
                v-on:click="$set(cred, 'visible', !cred.visible)"
              ><i class="icon" :class="cred.visible ? 'eye' : 'eye-slash'"></i></span>
            </div>
          </div>
          <small>{{ $t(cred.description) }}</small>
        </div>
      </div>
    </div>
    <div class="form-inline justify-content-center">
      <button
        type="submit"
        class="btn btn-theme"
        :disabled="!authCredentialsSet"
      >{{ error ? $t('Try again') : $t('Authenticate') }}</button>
    </div>
  </form>
</template>
<script>

export default {
  name: 'AuthForm',
  props: {
    authOptions: {
      type: Array
    },
    error: {
      type: String
    }
  },
  emits: ['submit'],
  data () {
    return {
      authMethod: '' // authentication method selected by a user
    }
  },
  computed: {
    authOption: function () {
      return this.authOptions.find(opt => (opt.method || '') === this.authMethod)
    },
    authCredentials: function () {
      return (this.authOption && this.authOption.info && this.authOption.info.credentials) || []
    },
    authCredentialsSet: function () {
      for (const cred of this.authCredentials) {
        if (cred.required && !cred.value) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    onSubmit: function () {
      this.$emit('submit', {
        authMethod: this.authMethod,
        authCredentials: this.authCredentials
      })
    }
  },
  mounted () {
    // Disable inputs with prefilled values from modification
    // We want to do this calculation only once and
    // do not update this value dynamically
    for (const option of this.authOptions) {
      if (option.info && option.info.credentials && option.info.credentials.length) {
        for (const cred of option.info.credentials) {
          cred.disabled = cred.value && !!cred.value.length
        }
      }
    }
    this.authMethod = (this.authOptions[0] && this.authOptions[0].method) || ''
  }
}
</script>
<style lang="scss" scoped>
.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
}

.eye {
  background-image: url(../assets/eye.svg);
}

.eye-slash {
  background-image: url(../assets/eye-slash.svg);
}
</style>
